
<div class="container-fluid" *ngIf="!detailPage">
  <div class="row content">
    <!--side nav-->
    <div class="col-sm-4 sidenav">

      <br>

      <!---dynamic accordion-->
      <form (ngSubmit) ="onKeySearch(f)" #f="ngForm">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true" >
          <div class="panel panel-default" *ngFor="let k of mySource">
            <div class="panel-heading" role="tab" >
              <h4 class="panel-title">
                <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#{{k.key}}" aria-expanded="false" aria-controls="collapseTwo">
                  <i class="more-less glyphicon glyphicon-plus"></i>
                  {{k.key}}
                </a>
              </h4>
            </div>
            <div id="{{k.key}}" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
              <div class="panel-body">
                <!--check box starts-->
                <div class="" *ngFor="let keywor of mySource">
                  <div class="col-md-6" *ngFor="let x of keywor.words">
                    <div *ngIf="k.key === keywor.key">
                      <label>
                        <input
                          type="checkbox"
                          name="{{x.code}}"
                          (change)="onAddItem(x.code, $event.target.checked)"
                          ngModel
                        >
                        {{x.description}}
                      </label>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!--dynamic accordion ends here-->
        <div class="text-center"><button class="btn btn-primary" type="submit">{{'SEARCH_BTN.NAME' | translate}}</button></div>
      </form>
      <!--dynamic accordion ends here-->

    </div><!--side nav-->
    <!--side nav-->
    <div class="col-sm-8">
      <h2><small>{{'MAIN_MENU.RTGANATOMY_TITLE' | translate}}</small></h2>
      <hr>
      <p *ngIf="beforeSearch">
        {{rtgDescription}}
      </p>

    </div>
    <!--{{skinDData | json}}-->
    <div class="col-sm-8" *ngIf="!beforeSearch">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <span>{{locOutput | lowercase}}</span>
        </div>
        <div class="panel-body">
          <table class="table-striped" width="100%">
            <tbody *ngFor="let d of images; let i = index">
            <!--<tr>
              <span>{{d.keywords}}</span>
            </tr>-->
            <tr >
              <td height="10"><a class="aclick" (click)="showDetaiPage(d.id)">{{d.diagnose}}<p class="b">{{d.group.name}}</p></a></td>

              <td class="pull-right" height="10">
                <a class="aclick" (click)="showDetaiPage(d.id)"><img id="seImage" src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/thumb100/' + d.img}}"
                                                                     class="img-responsive-height"></a>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>


  </div>
</div>

<!--detail page-->
<div class="container-fluid" *ngIf="detailPage">
  <div class="row">
    <div class="col-sm-4">
      <button class="aclick btn-primary" (click)="back()">Back</button>
    </div>
    <div class="col-sm-8">
      <h2><small>{{'MAIN_MENU.SKINDISEASE_TITLE' | translate}}</small></h2>
    </div>
  </div>
  <!--<button class="aclick btn-primary" (click)="uusiAku()">Tee uusi haku</button>-->
  <hr>
  <!--{{diagnoseData | json}}-->
  <div class="row">
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-12">
          <h4>{{diagnoseTitle}}</h4>
        </div>
        <div class="col-sm-12">
          <p>{{diagnoseDesc}}</p>
        </div>
        <div class="col-sm-12">
          <div class="row text-center">
            <div class="col-sm-3" *ngFor="let n of diagnoseData">
              <a class="aclick">
                <img src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/thumb100/' + n.img}}"
                     class="img-responsive-height img-thumbnail" data-toggle="modal" data-target="#image-gallery3"
                     (click)="setSelectedImage(n)">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="panel panel-primary">
        <div class="panel-heading">Erotusdiagnooseja</div>
        <div class="panel-body">
          <table class="table-striped" width="100%">
            <tbody>
            <tr *ngFor="let d of differential_diagnoses">
              <td height="10"><a (click)="showDetaiPage(d.id)" class="aclick">{{d.diagnose}}
                <p class="b">{{d.group}}</p></a></td>

              <td class="pull-right" height="10">
                <a (click)="showDetaiPage(d.id)" class="aclick">
                  <img id="seImage1" src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/thumb100/' + d.img}}"
                       class="img-responsive-height"></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="modal fade" id="image-gallery3" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="selectedImage">
      <div class="modal-body">
        <img src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/' + selectedImage.img}}" class="img-responsive">
      </div>
      <div class="modal-footer">
        <p>{{selectedImage.description}}</p><hr>
        <a class="pull-right glyphicon-color"><i class="glyphicon glyphicon-remove" data-dismiss="modal"></i></a>
        <a class="pull-left glyphicon-color">
          <i class="glyphicon glyphicon-circle-arrow-left" (click)="navigate(false)"></i></a>
        <a class="bt pull-left glyphicon-color">
          <i class="glyphicon glyphicon-circle-arrow-right" (click)="navigate(true)"></i></a>
      </div>
    </div>

  </div>
</div>-->

<div class="container">
  <div class="modal" id="image-gallery2"  role="dialog">
    <div class="modal-dialog">
      <div class="modal-header">
        <button class="close closeBtn hcolor" type="button" data-dismiss="modal">×</button>
      </div>
      <div class="modal-content" *ngIf="selectedImage">
        <div class="modal-body">
          <img src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/' + selectedImage.filename}}" class="img-responsive">
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="image-gallery3"  role="dialog">
    <div class="modal-dialog">
      <div class="modal-header">
        <button class="close closeBtn hcolor" type="button" data-dismiss="modal">×</button>
      </div>
      <div class="modal-content" *ngIf="selectedImage2">
        <div class="modal-body">
          <img src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/' + selectedImage2.filename}}" class="img-responsive">
        </div>
      </div>
    </div>
  </div>


  <div class="container bodyColor" *ngIf="selectedImage">
    <div class="row" >
      <div class="col-md-12">
        <div class="card">
          <div class="card-block cardBgColor" >

            <input type="checkbox" class="read-more-state" id="post-1" />
            <p class="read-more-wrap">{{selectedImage.caption}}<span class="read-more-target">
              {{selectedImage.additional_caption}}</span></p>
            <label for="post-1" class="read-more-trigger"></label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-block card-block2">

                <div id="thumbcarousel1" class="carousel slide" data-interval="false" >
                  <div class="carousel-inner">
                    <div class="row item active" >
                      <div class = "col-lg-4 col-md-4 col-xs-4">
                        <div class="clearfix">
                          <img src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/thumb100/' +  image.filename }}"
                               (click)=setSelectedImage(image) class="img-thumbnail">
                        </div>
                      </div>
                    </div>
                    <div class="row item " *ngFor="let n of arr; let i = index" >
                      <div class = "col-lg-4 col-md-4 col-xs-4"  *ngFor="let image of images | slice:3*i+3; let j = index">
                        <div class="clearfix " *ngIf=" j < 3 ">
                          <img src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/thumb100/' +  image.filename }}"
                               (click)=setSelectedImage(image) class="img-thumbnail">
                        </div>
                      </div>
                    </div>
                  </div><!-- /carousel-inner -->
                  <a class="left carousel-control" href="#thumbcarousel1" role="button" data-slide="prev">
                    <span class="glyphicon glyphicon-chevron-left"></span>
                  </a>
                  <a class="right carousel-control" href="#thumbcarousel1" role="button" data-slide="next">
                    <span class="glyphicon glyphicon-chevron-right"></span>
                  </a>
                </div> <!-- /thumbcarousel -->

              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card ">
              <div class="card-block card-block1 bodyColor">
                <a class="" rel="ligthbox">
                  <div class="" >

                    <img src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/' + selectedImage.filename}}"
                         data-toggle="modal" data-target="#image-gallery2"  class="selectedImage">
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-block card-block2">
                <div id="thumbcarousel2" class="carousel slide" data-interval="false" >
                  <div class="carousel-inner">
                    <div class="row item active" >
                      <div class = "col-lg-4 col-md-4 col-xs-4" *ngFor="let image of datasource2 | slice:0:3; let i = index">
                        <div class="clearfix">
                          <img src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/thumb100/' +  image.filename }}"
                               (click)=setSelectedImage2(image) class="img-thumbnail">
                        </div>
                      </div>
                    </div>
                    <div class="row item " *ngFor="let n of arr2; let i = index" >
                      <div class = "col-lg-4 col-md-4 col-xs-4"  *ngFor="let image of datasource2 | slice:3*i+3; let j = index">
                        <div class="clearfix " *ngIf=" j < 3 ">
                          <img src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/thumb100/' +  image.filename }}"
                               (click)=setSelectedImage2(image) class="img-thumbnail">
                        </div>
                      </div>
                    </div>
                  </div><!-- /carousel-inner -->
                  <a class="left carousel-control" href="#thumbcarousel2" role="button" data-slide="prev">
                    <span class="glyphicon glyphicon-chevron-left"></span>
                  </a>
                  <a class="right carousel-control" href="#thumbcarousel2" role="button" data-slide="next">
                    <span class="glyphicon glyphicon-chevron-right"></span>
                  </a>
                </div> <!-- /thumbcarousel -->
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card ">
              <div class="card-block card-block1 bodyColor ">
                <a class="" rel="ligthbox">
                  <div class="" >

                    <img src="{{'https://www.terveysportti.fi/sovellukset/dg/kuvat/' + selectedImage2.filename}}"
                         data-toggle="modal" data-target="#image-gallery3"  class="selectedImage">
                  </div>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div><br><br><br><br><br><br><br><br>
</div>
