<p-panel [header]="title" *ngIf="loaded">
  <table class="ui-table ui-table-responsive">
    <tr *ngFor="let diagnose of datasource">
      <td><a [routerLink]="[routeLink, diagnose.id]">{{diagnose.diagnose}}</a></td>
      <td style="text-align: center">
        <a [routerLink]="[routeLink, diagnose.id]">
          <img [src]="thumbPath + diagnose.img" class="thumb" alt="{{diagnose.diagnose}}">
        </a></td>
    </tr>
  </table>
</p-panel>
