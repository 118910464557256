import {Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent implements OnInit {

  @Input() graphicalSearch: boolean;
  @Input() nestedLocationsData: any;
  @Input() locationsData: any;
  @Input() tissuesData: any;
  @Input() typesData: any;
  @Input() galleryId: number;
  @Input() autoCompleteResultSize: number;
  @Input() showSex: boolean;
  @Input() showAge: boolean;
  @Input() showKeyword: boolean;
  @Input() searchType: string;
  @Input() routelink: string;
  @Input() searchTip: string;

  sidebarVisible = false;

  constructor() { }

  ngOnInit() {
  }

  closeSideBar() {
    this.sidebarVisible = false;
  }

  toggleView(event) {
    this.graphicalSearch = event;
  }
}
