import {Component, OnDestroy, OnInit} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ImageService } from './services/image-service';
import { MenuItem } from 'primeng/api';
import { Gallery } from './models/Gallery';
import { AuthService } from './services/auth.service';
import { UserInfo } from './models/UserInfo';
import { Router } from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private galleries$: Subscription;
  private userInfo$: Subscription;
  galleries: Array<Gallery>;
  menuItems: MenuItem[];
  userInfoItems: MenuItem[];
  userInfo: UserInfo;

  constructor(
    private translate: TranslateService,
    private imageService: ImageService,
    private authService: AuthService,
    private router: Router
  ) {

    translate.addLangs(['en', 'fi']);
    translate.setDefaultLang('en');
    translate.currentLang = this.translate.getDefaultLang();
  }

  async ngOnInit() {
    // this.galleries = await this.imageService.getGalleries(this.translate.currentLang);

    const token = await this.authService.getAccessToken();
    if (token) {
      this.userInfo = await this.authService.userInfo();
    }

    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.buildMenu();
    });

    // Subscribe galleries
    this.galleries$ = this.imageService.galleriesObservable$
      .subscribe(result => {
        this.galleries = result;
        this.buildMenu();
      });

    // Subscribe userInfo
    this.userInfo$ = this.authService.userInfoObservable$
      .subscribe(async result => {
        if (result) {
          this.userInfo = result;
          if (this.userInfo.email || this.userInfo.name) {
            this.galleries = await this.imageService.getGalleries(this.translate.currentLang);
          }
          this.buildMenu();
        }
      });


  }

  ngOnDestroy() {
    if (this.userInfo$) {
      this.userInfo$.unsubscribe();
    }
    if (this.galleries$) {
      this.galleries$.unsubscribe();
    }

  }

  switchLanguage = (lang: string) => {
    this.translate.use(lang);
    this.router.navigate(['/']);
  };

  async buildMenu() {

    if ((!this.galleries) || (!this.userInfo)) {
      return;
    }

    const logoutUrl = this.authService.getLogoutUrl();
    this.menuItems = [];
    const galleriesItems = this.galleries.map( gallery => {
      return {
        label: gallery.name,
        routerLink: [gallery.route]
      }
    });
    this.menuItems = [
      {
        label: await this.translate.get('NAVBAR_MENU.HOME').toPromise(),
        icon: 'pi pi-home',
        // styleClass: 'menubrand',
        styleClass: 'userinfo-menu',
        routerLink: ['/'],
      },
      {
        label: await this.translate.get('NAVBAR_MENU.GALLERIES').toPromise(),
        icon: 'pi pi-th-large',
        items: galleriesItems,
        styleClass: 'userinfo-menu'
      }
      /*
      {
        label: await this.translate.get('NAVBAR_MENU.LANGUAGES').toPromise(),
        icon: 'pi pi-cog',
        items: [
          {label: 'English', command: (event) => this.switchLanguage('en')},
          {label: 'Suomi', command: (event) => this.switchLanguage('fi')}
        ],
        styleClass: 'userinfo-menu'
      }
       */
    ];
    this.userInfoItems = [
      {
        label: this.userInfo.email || this.userInfo.name,
        icon: 'pi pi-user',
        styleClass: 'userinfo-menu',
        items: [
          {
            label: await this.translate.get('NAVBAR_MENU.LOGOUT').toPromise(),
            icon: 'pi pi-sign-out',
            url: logoutUrl
          }
        ]
      }
    ]
  }

}
