import {Component, OnDestroy, OnInit} from '@angular/core';

import * as locations from '../../../../assets/data/skinDisease/locations.json';
import * as types from '../../../../assets/data/skinDisease/types.json';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ImageService} from '../../../services/image-service';
import {ActivatedRoute, Router} from '@angular/router';
import { Subscription} from 'rxjs';
import {DiagnoseService} from '../../../services/diagnose.service';
import {SearchCriteria} from '../../../models/SearchCriteria';
import {DiagnoseSearchResult} from '../../../models/DiagnoseSearchResult';
import {UtilsService} from '../../../services/utils.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {

  private searchResult$: Subscription;
  private searchCriteria$: Subscription;

  public searchResult: DiagnoseSearchResult = null;
  public searchCriteria: SearchCriteria;
  public locationsData: any = (locations as any).default;
  public typeData: any = (types as any).default;
  public galleryId: number;
  public locationsList: Array<any>;
  public typesList: Array<any>;
  public dataList: Array<any>;
  public searchResultKeysList: Array<any>;
  public showSearchResult = false;
  public lang: string;
  public thumbPath = 'https://www.terveysportti.fi/sovellukset/dg/kuvat/thumb/';

  constructor(
    private translate: TranslateService,
    private imageService: ImageService,
    private diagnoseService: DiagnoseService,
    private utilsService: UtilsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.lang = this.translate.currentLang;
    this.typesList = [];
    this.locationsList = [];
    this.dataList = [];
    this.typesList.push(...this.typeData[this.lang]);
    this.locationsList.push(...this.locationsData[this.lang]);
    this.dataList.push(...this.typeData[this.lang]);
    this.dataList.push(...this.locationsData[this.lang]);
  }

  ngOnInit() {

    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.lang = this.translate.currentLang;
      });
    this.galleryId = this.activatedRoute.parent.snapshot.data['galleryId'];

    this.searchResult$ = this.diagnoseService.diagnoseSearchResultObservable$
      .subscribe(
        result => {
          this.searchResult = result;
          if (result) {
            this.showSearchResult = true;
            this.searchResultKeysList = Array.from(this.searchResult.resultGroups.keys()).map( item => {
              const temp = this.utilsService.translateSearchCriteria(item, this.dataList);
              return {
                key: item,
                description: temp
              }
            });
            console.log(this.searchResultKeysList);
          }
        }
      );

    // Subscribe search criterias and search result from imageService
    this.searchCriteria$ = this.diagnoseService.searchCriteriaObservable$
      .subscribe(
        result => {
          this.searchCriteria = result;
          if (this.searchCriteria) {
            this.searchCriteria.location = this.utilsService.translateSearchCriteria(this.searchCriteria.location, this.locationsList);
            this.searchCriteria.types = this.utilsService.translateSearchCriteria(this.searchCriteria.types, this.typesList);
          }
        }
      );

  }

  ngOnDestroy() {
    if (this.searchResult$) {
      this.searchResult$.unsubscribe();
    }
  }


}
