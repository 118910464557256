import { Component, OnInit } from '@angular/core';
import { ImageService } from '../../services/image-service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { environment} from '../../../environments/environment';
import {Gallery} from '../../models/Gallery';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-gallery-home',
  templateUrl: './galleryHome.component.html',
  styleUrls: ['./galleryHome.component.scss']
})
export class GalleryHomeComponent implements OnInit {

  private imageBaseUrl = environment.IMAGE_BASE_URL;
  private galleries$: Subscription;
  galleries: Array<Gallery>;

  constructor(
    private translate: TranslateService,
    private imageService: ImageService) {}

  async ngOnInit() {

    this.translate.onLangChange
      .subscribe(async (event: LangChangeEvent)  => {
        this.galleries = await this.imageService.getGalleries(this.translate.currentLang);
      });

    // Subscribe galleries
    this.galleries$ = this.imageService.galleriesObservable$
      .subscribe(result => {
        this.galleries = result;
      });
  }

}
