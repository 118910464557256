import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule  } from '@angular/forms';
import { RouterModule  } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import {SidebarModule} from 'primeng/sidebar';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DialogModule} from 'primeng/dialog';
import {SliderModule} from 'primeng/slider';
import {SelectButtonModule} from 'primeng/selectbutton';
import {PanelModule} from 'primeng/panel';
import {CardModule} from 'primeng/card';
import { LightboxModule } from 'ngx-lightbox';
import { GalleryComponent} from './gallery/gallery.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { FlatCheckBoxListComponent } from './flat-check-box-list/flat-check-box-list.component';
import { NestedCheckBoxListComponent } from './nested-check-box-list/nested-check-box-list.component';
import { SearchCriteriaPanelComponent } from './search-criteria-panel/search-criteria-panel.component';
import { DiagnoseListComponent } from './diagnose-list/diagnose-list.component';
import { DiagnoseComponent } from './diagnose/diagnose.component';
import { AnatomyBodyComponent } from './anatomy-body/anatomy-body.component';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
  declarations: [
    GalleryComponent,
    SearchFormComponent,
    SearchPanelComponent,
    FlatCheckBoxListComponent,
    NestedCheckBoxListComponent,
    SearchCriteriaPanelComponent,
    DiagnoseListComponent,
    DiagnoseComponent,
    AnatomyBodyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SidebarModule,
    CheckboxModule,
    ButtonModule,
    AccordionModule,
    AutoCompleteModule,
    DialogModule,
    SliderModule,
    SelectButtonModule,
    PanelModule,
    CardModule,
    LightboxModule

  ],
  exports: [
    GalleryComponent,
    SearchPanelComponent,
    SearchCriteriaPanelComponent,
    DiagnoseListComponent,
    DiagnoseComponent,
    AnatomyBodyComponent
  ]
})
export class GalleryCommonModule { }
