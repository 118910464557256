import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SearchCriteria} from '../../../models/SearchCriteria';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ImageService} from '../../../services/image-service';

import * as sex from '../../../../assets/data/sex.json';
import {NestedCheckBoxListComponent} from '../nested-check-box-list/nested-check-box-list.component';
import {FlatCheckBoxListComponent} from '../flat-check-box-list/flat-check-box-list.component';
import {DiagnoseService} from '../../../services/diagnose.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

  @Input() graphicalSearch = true;
  @Input() nestedLocationsData: any;
  @Input() locationsData: any;
  @Input() tissuesData: any;
  @Input() typesData: any;
  @Input() galleryId: number;
  @Input() autoCompleteResultSize: number;
  @Input() showSex: boolean;
  @Input() showAge: boolean;
  @Input() showKeyword: boolean;
  @Input() searchType: string;
  @Input() routelink: string;
  @Input() searchTip: string;
  @Output() searchDone = new EventEmitter<boolean>();
  @Output() toggleSearchView = new EventEmitter<boolean>();

  @ViewChild('nestedLocations') nestedLocations: NestedCheckBoxListComponent;
  @ViewChild('flatLocations') flatLocations: NestedCheckBoxListComponent;
  @ViewChild('tissue') tissue: FlatCheckBoxListComponent;
  @ViewChild('types') types: FlatCheckBoxListComponent;

  private sexData: any = (sex as any).default;
  private autoCompleteSearchCriteria: SearchCriteria;

  public toggleViewBtnLabel: string;
  public showToggleBtn: boolean;
  lang: string;
  minAge = 0;
  maxAge = 15;

  keyword: string;
  selectedAge: number;
  selectedSex: any;
  autoCompleteData: string[];

  constructor(
    private translate: TranslateService,
    private imageService: ImageService,
    private diagnoseService: DiagnoseService,
    private router: Router
  ) {

    if (this.showAge) {
      this.selectedAge = this.minAge;
    }
    this.lang = this.translate.currentLang;
  }

  ngOnInit() {
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.lang = this.translate.currentLang;
        this.getTranslations();
      });

    this.autoCompleteSearchCriteria = new SearchCriteria();
    this.autoCompleteSearchCriteria.galleryId = this.galleryId;
    this.autoCompleteSearchCriteria.size = this.autoCompleteResultSize;
    this.getTranslations();

    this.showToggleBtn = false;
    if (this.galleryId === 1 || this.galleryId === 6 || this.galleryId === 8) {
      this.showToggleBtn = true;
    }
  }

  getTranslations() {
    let label = 'SEARCH_BTN.ADVANCED';
    if (!this.graphicalSearch) {
      label = 'SEARCH_BTN.GRAPHICAL';
    }
    this.translate.get(label).subscribe( result => {
      this.toggleViewBtnLabel = result;
    });
  }

  async autoCompleteSearch(event) {
    this.autoCompleteSearchCriteria.keyword = event.query.toLowerCase();
    this.autoCompleteData = await this.imageService.gallerySuggestion(this.autoCompleteSearchCriteria);
  }

  async search(kw?: string) {
    const _searchCriteria = new SearchCriteria();
    _searchCriteria.language = this.translate.currentLang;
    _searchCriteria.galleryId = this.galleryId;

    // Anatomikuvastoissa näytetään vain Waldeyerin kuvat
    if ((this.galleryId === 1) || (this.galleryId === 6) || (this.galleryId === 8)) {
      _searchCriteria.source = 'waldeyer';
    }

    if (kw || this.keyword) {
      _searchCriteria.keyword = kw || this.keyword;
    }

    if (this.nestedLocations) {
      if (this.nestedLocations.selectedItems) {
        _searchCriteria.location = this.nestedLocations.selectedItems.join(',');
      }
    }

    if (this.flatLocations) {
      if (this.flatLocations.selectedItems) {
        _searchCriteria.location = this.flatLocations.selectedItems.join(',');
      }
    }

    if (this.tissue) {
      if (this.tissue.selectedItems) {
        _searchCriteria.tissue = this.tissue.selectedItems.join(',');
      }
    }

    if (this.types) {
      if (this.types.selectedItems) {
        _searchCriteria.types = this.types.selectedItems.join(',');
      }
    }

    if (this.selectedSex) {
      _searchCriteria.sex = this.selectedSex.code;
    }

    if (this.selectedAge) {
      _searchCriteria.age = this.selectedAge;
    }

    // Make search. ImageService put's the search result and search criteria
    // to shared observables which are subscribed by different galleries.
    if (this.searchType === 'DIAGNOSE') {
      await this.diagnoseService.searchDiagnoses(_searchCriteria);
    } else {
      await this.imageService.searchImages(_searchCriteria);
    }

    // Clear search fields after search
    this.keyword = '';
    this.selectedSex = null;
    this.selectedAge = null;
    if (this.nestedLocations) {
      this.nestedLocations.selectedItems = [];
    }
    if (this.flatLocations) {
      this.flatLocations.selectedItems = [];
    }
    if (this.tissue) {
      this.tissue.selectedItems = [];
    }
    if (this.types) {
      this.types.selectedItems = [];
    }

    this.searchDone.emit(false);

    // If routelink has given go there
    if (this.routelink) {
      this.router.navigate([this.routelink]);
    }
  }

  toggleView() {
    this.graphicalSearch = !this.graphicalSearch;
    this.getTranslations();
    this.toggleSearchView.emit(this.graphicalSearch);
  }
}
