export class SearchCriteria {

  galleryId: number;
  language: string;
  keyword: string;
  location: string;
  tissue: string;
  types: string;
  age: number;
  sex: string;
  size: number;
  resultCount: number;
  source: string;

  constructor() {
    this.size = 1000;
  }
}
