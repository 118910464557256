export const environment = {
  production: true,
  apiBaseUrl: 'https://api.medicalpicturegallery.com/dg/',
  loginBaseUrl: 'https://gallery.auth.eu-west-1.amazoncognito.com/login',
  logoutBaseUrl: 'https://gallery.auth.eu-west-1.amazoncognito.com/logout',
  clientId: 'othg5aa7iagkeq2dlegcflsrl',
  appLoginUrl: 'https://www.medicalpicturegallery.com/login',
  appLogoutUrl: 'https://www.medicalpicturegallery.com/logout',
  refreshTokenStorageName: 'refresh-token',
  accessTokenStorageName: 'access-token',

  SEARCH_API: 'gallery/search/',
  DIAGNOSE_SEARCH_API: 'diagnose/search/',
  IMAGE_IMAGE_API: 'image/',
  FRONT_PAGE_DESCRIPTION: 'galleries/',
  IMAGE_BASE_URL: 'https://www.terveysportti.fi/sovellukset/dg/images/',
  GALLERY_IMAGE_BASE_URL: 'https://www.terveysportti.fi/sovellukset/dg/kuvat/',

  IMAGE_LOCATION_API: 'http://185.6.180.187/galleryApi/api/location/',
  IMAGE_KEYWORD_API: 'http://185.6.180.187/galleryApi/api/search/',
  IMAGE_IMAGE_API1: 'http://tstapi.duodecim.fi/dg/gallery/image/',
  SKIN_DISEASE_API: 'http://tstapi.duodecim.fi/dg/gallery/search/diagnoses',
  GENERAL_SEARCH_API: 'http://tstapi.duodecim.fi/dg/galleries/search',
  DIAGNOSE_API: 'http://tstapi.duodecim.fi/dg/diagnose/',
  DIAGNOSES_API: 'http://tstapi.duodecim.fi/dg/diagnoses/',
  AUTOFIL_URL: 'http://tstapi.duodecim.fi/dg/diagnose/suggestion/skindisease/',
  NEW_SEARCH_URL: 'http://tstapi.duodecim.fi/dg/gallery/search',
  ANATOMY_KEY: './shared/keyword.json'
};
