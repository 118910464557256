import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-nested-check-box-list',
  templateUrl: './nested-check-box-list.component.html',
  styleUrls: ['./nested-check-box-list.component.scss']
})
export class NestedCheckBoxListComponent implements OnInit {

  @Input() title: string;
  @Input() name: string;
  @Input() nestedItems: any;

  public selectedItems: Array<string>;

  constructor() { }

  ngOnInit() {
  }
}
