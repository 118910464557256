import {Inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LOCAL_STORAGE, WebStorageService} from 'ngx-webstorage-service';
import {Tokens} from '../models/Tokens';
import {UserInfo} from '../models/UserInfo';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = `${environment.apiBaseUrl}`;
  public userInfoObservable$ = new BehaviorSubject<UserInfo>(null);
  constructor(
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) { }

  setUserInfo(userInfo: UserInfo) {
    this.userInfoObservable$.next(userInfo);
  }

  login(code: string): Observable<any> {
    const url = `${this.baseUrl}login/${code}`;
    return this.http.get<Tokens>(url)
      .pipe(
        tap( async data => {
          await this.setAccessToken(data.access_token);
          await this.setRefreshToken(data.refresh_token);
        })
      )
  }

  async userInfo(): Promise<UserInfo> {
    let userInfo = this.userInfoObservable$.getValue();
    if (userInfo) {
      return userInfo;
    }
    const url = `${this.baseUrl}userinfo`;
    userInfo = await this.http.get<UserInfo>(url).toPromise();
    this.setUserInfo(userInfo);
    return userInfo;
  }

  async checkSession(session): Promise<UserInfo> {
    const url = `${this.baseUrl}auth/${session}`;
    const userInfo = await this.http.get<any>(url).toPromise();
    await this.setAccessToken(userInfo.token.access_token);
    this.setUserInfo(userInfo);
    return userInfo;
  }

  getAccessToken(): string {
    try {
      return this.storage.get(environment.accessTokenStorageName);
    } catch (err) {
      return null;
    }
  }

  getRefreshToken(): string {
    return this.storage.get(environment.refreshTokenStorageName);
  }

  async setAccessToken(token: string) {
    await this.storage.set(environment.accessTokenStorageName, token);
  }

  setRefreshToken(token: string) {
    this.storage.set(environment.refreshTokenStorageName, token);
  }

  removeAccessToken() {
    this.storage.remove(environment.accessTokenStorageName);
  }

  removeRefreshToken() {
    this.storage.remove(environment.refreshTokenStorageName);
  }


  getLoginUrl(): string {
    // tslint:disable-next-line:max-line-length
    return `${environment.loginBaseUrl}?client_id=${environment.clientId}&response_type=code&redirect_uri=${environment.appLoginUrl}`;

  }

  getLogoutUrl(): string {
    return `${environment.logoutBaseUrl}?client_id=${environment.clientId}&logout_uri=${environment.appLogoutUrl}`;
  }}
