<article *ngIf="diagnose" class="p-grid">

  <section class="p-col-12">
    <h2>{{diagnose.diagnose}}</h2>
    <!-- <div><i>{{diagnose.group.name}}</i></div> -->
  </section>

  <section class="p-col-12 p-lg-9 p-md-8 ">
    <section *ngIf="lang === 'fi'">
        <h4>{{'DIAGNOSE.ICD10' | translate}}</h4>
        <div>{{diagnose.icd.primary_code}} {{diagnose.icd.description}}</div>
    </section>

    <section *ngIf="diagnose.description">
      <p>{{diagnose.description}}</p>
    </section>

    <section>
      <app-gallery
        [galleryId]="galleryId"
        [datasource]="diagnose.images"
      ></app-gallery>
    </section>
  </section>

  <section class="p-col-sm-hidden p-col p-lg-3 p-md-4 ">
    <app-diagnose-list [galleryId]="galleryId"
                        title="{{'DIAGNOSE.DIFFERENTIAL_DIAGNOSE' | translate}}"
                       [datasource]="diagnose.differential_diagnoses"
                       [routeLink]="'../'"
    ></app-diagnose-list>
  </section>

</article>
