import {Component, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import {Diagnose} from '../../../models/Diagnose';

@Component({
  selector: 'app-diagnose-list',
  templateUrl: './diagnose-list.component.html',
  styleUrls: ['./diagnose-list.component.scss']
})
export class DiagnoseListComponent implements OnInit, OnChanges {

  @Input() datasource: Array<Diagnose>;
  @Input() title: string;
  @Input() galleryId: number;
  @Input() routeLink: string;
  public thumbPath = 'https://www.terveysportti.fi/sovellukset/dg/kuvat/thumb/';
  public loaded = false;

  constructor() { }

  buildDiagnoseList() {
    this.loaded = true;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {

    for (const propName in changes) {
      if (propName === 'datasource') {
        this.buildDiagnoseList();
      }
    }
  }
}
