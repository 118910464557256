<!-- Search panel -->
<div class="p-col-xss-hidden">
  <p-button [icon]="'pi pi-search'" (click)="this.sidebarVisible = true" label="{{'SEARCH_BTN.NAME' | translate }}"></p-button>
</div>

<section class="p-col-xs-hidden">
  <app-search-form
    [graphicalSearch]="graphicalSearch"
    [galleryId]="galleryId"
    [showKeyword]="showKeyword"
    [showAge]="showAge"
    [showSex]="showSex"
    [nestedLocationsData]="nestedLocationsData"
    [locationsData]="locationsData"
    [tissuesData]="tissuesData"
    [typesData]="typesData"
    [autoCompleteResultSize]="autoCompleteResultSize"
    [searchType]="searchType"
    [routelink]="routelink"
    [searchTip]="searchTip"
    (toggleSearchView)="toggleView($event)"
  ></app-search-form>
</section>

<p-sidebar [(visible)]="sidebarVisible" *ngIf="sidebarVisible">
  <app-search-form
    [graphicalSearch]="graphicalSearch"
    [galleryId]="galleryId"
    [showKeyword]="showKeyword"
    [showAge]="showAge"
    [showSex]="showSex"
    [nestedLocationsData]="nestedLocationsData"
    [locationsData]="locationsData"
    [tissuesData]="tissuesData"
    [typesData]="typesData"
    [autoCompleteResultSize]="autoCompleteResultSize"
    [searchType]="searchType"
    [routelink]="routelink"
    [searchTip]="searchTip"
    (searchDone)="closeSideBar()"
    (toggleSearchView)="toggleView($event)"
  ></app-search-form>
</p-sidebar>


