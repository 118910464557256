<section class="p-grid">
  <section class="p-col-12 menu">
    <h2 class="menubrand">{{'NAVBAR_MENU.LOGO' | translate}}</h2>
    <p-menubar [model]="menuItems">
      <div>
        <p-menubar [model]="userInfoItems"></p-menubar>
      </div>
    </p-menubar>
  </section>
  <section class="p-col-12 main-content">
    <router-outlet></router-outlet>
  </section>
  <!--
  <section class="p-col-12 footer">
  </section>
  -->
</section>

