// ...
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {AuthService} from '../services/auth.service';

@Injectable()
export class UnathorizedInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            return;
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 400) {
              // Build redirect url, so that user ends up to the same page where he has left
              // TODO: URL object is not working in IE that's why try...catch block.
              this.authService.removeAccessToken();
              this.authService.removeRefreshToken();
              const url = this.authService.getLoginUrl();
              /*
            try {
              const queryParams = new URL(document.location.toString()).searchParams.get('search');
              if (queryParams) {
                url = `${url}?search=${queryParams}`;
              }
            } catch (err) {
              console.log(err);
            }
            */
              window.location.replace(url);
            }
          }
        }
      )
    );
  }
}
