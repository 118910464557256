<app-anatomy-body
  *ngIf="graphicalSearch"
  [galleryId]="galleryId"
  [autoCompleteResultSize]="autoCompleteResultSize"
  [searchTip]="searchTip"
  (search)="search($event)"
></app-anatomy-body>

<form (ngSubmit)="search()" *ngIf="!graphicalSearch">

  <!-- Keyword with autocomplete -->
  <div class="form-section" *ngIf="showKeyword">
    <div class="p-inputgroup">
      <p-autoComplete
        [size]="40"
        name="keyword"
        [minLength]="3"
        [(ngModel)]="keyword"
        [suggestions]="autoCompleteData"
        (completeMethod)="autoCompleteSearch($event)"
        placeholder="{{searchTip}}"
      >
      </p-autoComplete>
      <span class="p-inputgroup-addon" (click)="search()"><i class="pi pi-search"></i></span>
    </div>
  </div>

  <div class="text-center">
    <button pButton class="p-color-dark" icon="pi pi-search"  type="submit" label="{{'SEARCH_BTN.NAME' | translate}}"></button>
  </div>

  <!-- Age selection slider -->
  <div class="form-section" *ngIf="showAge">
    <h4>Ikä:</h4>
    <p-slider
      [(ngModel)]="selectedAge"
      [name]="'age'"
      [min]="minAge"
      [max]="maxAge"
      [step]="1"
    ></p-slider>
    <div>{{selectedAge}} vuotta</div>
  </div>

  <!-- Sex selector -->
  <div class="form-section" *ngIf="showSex">
    <h4>Sukupuoli:</h4>
    <p-selectButton
      [options]="sexData[lang]"
      [(ngModel)]="selectedSex"
      [name]="'sex'"
      [optionLabel]="'description'"
      [dataKey]="'code'"
    ></p-selectButton>
  </div>
  <!-- Nested locations data in accordion -->
  <div class="form-section" *ngIf="nestedLocationsData">
    <app-nested-check-box-list
      #nestedLocations
      title="{{'SIDE_NAV.SEARCHTYPE1' | translate}}"
      [name]="'locations'"
      [nestedItems]="nestedLocationsData"
    ></app-nested-check-box-list>
  </div>

  <!-- Flat locations data -->
  <div class="form-section" *ngIf="locationsData">
    <app-flat-check-box-list
        #flatLocations
        title="{{'SIDE_NAV.SEARCHTYPE1' | translate}}"
        [name]="'locations'"
        [items]="locationsData"
    ></app-flat-check-box-list>
  </div>

  <!-- Flat tissues data -->
  <div class="form-section" *ngIf="tissuesData">
    <app-flat-check-box-list
      #tissue
      title="{{'SIDE_NAV.SEARCHTYPE2' | translate}}"
      [name]="'tissue'"
      [items]="tissuesData"
    ></app-flat-check-box-list>
  </div>

  <!-- Flat types data -->
  <div class="form-section" *ngIf="typesData">
    <app-flat-check-box-list
      #types
      title="{{'SIDE_NAV.SEARCHTYPE3' | translate}}"
      [name]="'types'"
      [items]="typesData"
    ></app-flat-check-box-list>
  </div>

  <div class="text-center p-mt-2">
    <button pButton class="p-color-dark" icon="pi pi-search"  type="submit" label="{{'SEARCH_BTN.NAME' | translate}}"></button>
  </div>

</form>
<!--
<div class="p-mt-2" *ngIf="showToggleBtn">
  <button pButton class="p-color-dark" (click)="toggleView()" label="{{toggleViewBtnLabel}}" icon="pi pi-arrow-left"></button>
</div>
-->
