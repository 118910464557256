import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../environments/environment';


@Injectable()
export class ImageImageService {
  private query: number;
  tempUrl ;
  private IMAGE_IMAGE_API = environment.IMAGE_IMAGE_API;
  private IMAGE_IMAGE_API1 = environment.IMAGE_IMAGE_API1;

  constructor(private http: HttpClient) { }

  getImage(query): any {
    return this.http.get<any>(this.IMAGE_IMAGE_API + query);
  }

  getImage1(language, galleryId, imageId): any {
    this.tempUrl = this.IMAGE_IMAGE_API1 + language + '/' + galleryId + '/' + imageId;
    return this.http.get<any>(this.tempUrl);
  }

}
