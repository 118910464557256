import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import { IhotautikuvastoService } from '../../services/ihotautikuvasto.service';
import { ImageService } from '../../services/image-service';


import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ImageImageService } from '../../services/image-image-service';


import { Autofill } from './autofill';
import { KeyLocation} from './key-location';

import 'rxjs/add/observable/of';


@Component({
  selector: 'app-rontgen-anatomiakuvasto',
  templateUrl: './rontgen-anatomiakuvasto.component.html',
  styleUrls: ['./rontgen-anatomiakuvasto.component.css']
})
export class RontgenAnatomiakuvastoComponent implements OnInit {
  @ViewChild('f') searcKeyForm: NgForm;
  @Input() diagnoseData;
  @Input() locOutput;
  @Input() beforeSearch = true;

  location = [];
  keyword2 = [];
  typpe;
  loc;
  locTemp1;
  locTemp2;
  key3;
  searchquery;
  keyWsearch;
  tempKeyW;
  skinDData;
  info;
  diagnoseTitle;
  diagnoseDesc;
  differential_diagnoses;
  selectedImage;
  infoavailable = false;
  detailPage = false;

  autoFill: any;
  mysourceFirst: any;
  tempKeyword = [] ;
  keyword = [];
  autofill2 = [];

  mySource = [];


  searchKey = {
    'language': 'fi',
    'galleryId': 3,
    'keyword': '',
    'location': ''
  }

  list2;
  datasource2;
  dataSourceLength2;
  selectedImage2;
  selected2;
  arr2 ;
  arr= [];
  currLanguage;
  keywordSearchToDis;
  keywordSearch;
  searching = false;
  searchCriterias;
  images;
  dataSourceLength;
  selected;
  imagesFound = false;
  rtgDescription;
  frontData;
  browserLang;
  constructor(
    private imageService2: ImageImageService,
    private translate: TranslateService,
    private ihotoService: IhotautikuvastoService,
    private imgSer: ImageService) {
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.browserLang = event.lang
      this.getFrontDesc(this.browserLang);
    });

    if (this.translate.defaultLang) {
      this.getFrontDesc(this.translate.defaultLang);
    }
    this.autoFill = new Autofill();
    this.autofill2 = this.autoFill.Autofill;
    this.mysourceFirst = new KeyLocation();
    this.mySource = this.mysourceFirst.mySource;
  }

  async getFrontDesc(lang) {
    lang = this.translate.currentLang;
    this.frontData = await this.imgSer.getGalleries(lang);
        for (let i = 0; i < this.frontData.length; i++) {
          if (this.frontData[i].id === 5) {
            this.rtgDescription = this.frontData[i].description;
          }
        }
        return this.frontData;
  }
  onAddItem(key: string, isActive: boolean) {
    const index: number = this.location.indexOf(key);

    if (isActive) {
      this.location.push(key);
    } else {
      this.location.splice(index, 1);
    }
  }
  onAddItem2(key: string, isActive: boolean) {
    const index: number = this.keyword2.indexOf(key);

    if (isActive) {
      this.keyword2.push(key);
    } else {
      this.keyword2.splice(index, 1);
    }
  }
  onKeySearch() {
    this.typpe = this.keyword2.join(',');
    this.loc = this.location.join(',');
    this.searchKey.location = this.loc;
    this.searchKey.language = this.translate.currentLang;
    if (this.searchquery) {
      this.searchKey.keyword = this.searchquery;
    }
    this.searchImagesLocation2(this.searchKey);
    this.searchquery = '';
    this.keyword2 = [];
    this.location = [];
    this.searcKeyForm.resetForm();
    this.beforeSearch = false;
  }

  searchWithKeyword() {
    this.searchKey.keyword = this.searchquery;
  }
  clearForm() {
    this.searcKeyForm.resetForm();
  }
  searchSkinDisease(key) {
    this.ihotoService.getSkinDisease( key).subscribe(
      (response) => {
        const data = response;
        this.skinDData = data.results;
        console.log(this.skinDData);
        this.info = data.info;

        this.locTemp1 = this.info.keywords;
        this.locTemp2 = this.locTemp1.slice(0, -1);
        this.locOutput = this.locTemp2.toUpperCase();

        console.log(this.locOutput);
        this.infoavailable = true;
      }
    )
  }
  searchImagesLocation2(key) {
    console.log(key);

    this.keywordSearchToDis = this.keywordSearch;
    this.searching = true;

    return this.imgSer.getImageNew(key).subscribe(
      (response) => {
        const data = response;
        this.info = data.info;
        this.searchCriterias = this.info.searchCriterias;
        this.locOutput = this.searchCriterias.location;
        console.log(this.locOutput);
        this.images = data.results;
        // this.images = this.images.concat(data.results);
        console.log(this.images);
        if (this.images.length > 3) {

          this.dataSourceLength = this.images.length - 3;
          this.selected = this.dataSourceLength / 3;
          this.arr = Array(Math.ceil(this.selected)).fill(4);
        } else {
          this.dataSourceLength = this.images.length;
          this.selected = this.dataSourceLength;
          this.arr = Array(Math.ceil(this.selected)).fill(4);
        }

        console.log('arr' + this.arr.length);
        this.searching = false;
        this.imagesFound = true;

        return this.images;
      }
    );
  };
  onKeydown(event) {
    this.keyWsearch = event.target.value;
    this.tempKeyW = this.keyWsearch;
    this.searchKey.keyword = this.tempKeyW;
  }

  showDetaiPage(key) {
    this.detailPage = true;
    this.ihotoService.getDiagnose(this.translate.currentLang, key, this.searchKey.galleryId).subscribe(
      (response) => {
        const data = response;
        this.diagnoseDesc = data.description;
        this.diagnoseTitle = data.diagnose;
        this.diagnoseData = data.images;
        this.differential_diagnoses = data.differential_diagnoses;
      }
    )
  }

  back() {
    this.detailPage = false;
  }
  uusiAku() {
    window.location.reload();
  }

  navigate(forward) {
    const index = this.diagnoseData.indexOf(this.selectedImage) + (forward ? 1 : -1);
    if (index >= 0 && index < this.diagnoseData.length) {
      this.selectedImage = this.diagnoseData[index];
    }
  }
  observableSource(e) {
    this.key3 = e;
    this.ihotoService.getAutofill(this.translate.currentLang, this.key3).subscribe(
      (res) => {
        const data = res;
        this.list2 = data;
        return this.list2;
      }
    )
    const filteredList = this.list2;
    return Observable.of(filteredList);
  }

  searchImageImage1(language, galleryId, imageId) {
    return this.imageService2.getImage1(language, galleryId, imageId).subscribe(
      (response) => {
        const data = response;
        this.datasource2 = data.related_images;

        if (this.datasource2.length > 0) {
          this.selectedImage2 = this.datasource2[0];
          if (this.datasource2.length > 3) {

            this.dataSourceLength2 = this.datasource2.length - 4 ;
            this.selected2 = this.dataSourceLength2 / 3;
            this.arr2 = Array(Math.ceil(this.selected2)).fill(4);
          } else {
            this.dataSourceLength2 = this.datasource2.length;
            this.selected2 = this.dataSourceLength2;
            this.arr2 = Array(Math.ceil(this.selected2)).fill(4);
          }
        }

        return this.datasource2;
      }
    );
  };
  setSelectedImage(image) {
    this.selectedImage = image;
  }
  setSelectedImage3(image) {
    this.selectedImage = image;
    this.currLanguage = this.searchKey.language;
    this.selectedImage2 = this.selectedImage;
    this.searchImageImage1(this.currLanguage, 5, this.selectedImage.image_id);
    this.datasource2 = [];
    this.arr2 = [];
  }

  setSelectedImage2(image) {
    this.selectedImage2 = image;
  }
}
