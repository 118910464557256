<div class="p-grid">

  <div *ngFor="let gallery of galleries" class="p-col-12 p-sm-6  card-wrapper">
   <a [routerLink] = "[gallery.route]">
      <p-card [header]="gallery.name" styleClass="ui-card-shadow">
        <p-header>
          <img class="card-img-top" src="{{imageBaseUrl +  gallery.image }}">
        </p-header>

       <!--  <p>{{gallery.shortDescription}}</p> -->
    </p-card>
    </a>
  </div>
</div>
