
  export class KeyLocation {
  mySource = [
    {
      key: 'Pää',
      words: [
        {'code': 'aivot', 'description': 'Aivot'},
        {'code': 'pääkallo', 'description': 'Pääkallo'},
        {'code': 'poskiontelot', 'description': 'Poskiontelot'},
        {'code': 'leuka', 'description': 'Leuka'},
        {'code': 'kaula', 'description': 'Kaula'}
      ]
    },
    {
      key: 'Yläraaja',
      words: [
        {'code': 'olkapää/olkavarasi', 'description': 'Olkapää/olkavarasi'},
        {'code': 'kyynärpää', 'description': 'kyynärpää'},
        {'code': 'käsivarsi/ranne', 'description': 'käsivarsi/ranne'},
        {'code': 'kämmen/sormet', 'description': 'kämmen/sormet'}
      ]
    },
    {
      key: 'Rintakehä',
      words: [
        {'code': 'rintakehä', 'description': 'Rintakehä'},
        {'code': 'keuhko', 'description': 'Keuhko'},
        {'code': 'sydän', 'description': 'Sydän'},
        {'code': 'aortta', 'description': 'Aortta'},
        {'code': 'selka', 'description': 'Selka'},
        {'code': 'selkäranka', 'description': 'Selkäranka'},
        {'code': 'ruokatorvi', 'description': 'Ruokatorvi'}
      ]
    },
    {
      key: 'Vatsa',
      words: [
        {'code': 'vatsa', 'description': 'Vatsa'},
        {'code': 'maksa', 'description': 'Maksa'},
        {'code': 'sappirakko', 'description': 'Sappirakko'},
        {'code': 'perna', 'description': 'Perna'},
        {'code': 'munuaiset', 'description': 'Munuaiset'},
        {'code': 'vatsalaukku', 'description': 'Vatsalaukku'},
        {'code': 'ohutsuoli', 'description': 'Ohutsuoli'},
        {'code': 'Paksusuoli', 'description': 'Paksusuoli'}
      ]
    },
    {
      key: 'Genitaalit',
      words: [
        {'code': 'genitaalit', 'description': 'Genitaalit'},
        {'code': 'virtsarakko', 'description': 'Virtsarakko'}
      ]
    },
    {
      key: 'Alaraaja',
      words: [
        {'code': 'lonkka', 'description': 'Lonkka'},
        {'code': 'reisi', 'description': 'Reisi'},
        {'code': 'polvi', 'description': 'Polvi'},
        {'code': 'sääri', 'description': 'Sääri'},
        {'code': 'nilkka', 'description': 'Nilkka'},
        {'code': 'jalkaterä/varpaat', 'description': 'Jalkaterä/varpaat'}
      ]
    }
  ]
}

