<section class="p-grid">
  <section class="p-col-12 p-sm-5 left-panel">
    <app-search-panel
      [galleryId]="galleryId"
      [showKeyword]="true"
      [nestedLocationsData]="locationsData[lang]"
      [tissuesData]="tissuesData[lang]"
      [autoCompleteResultSize]="10"
      [graphicalSearch]="true"
      searchTip="{{'SIDE_NAV.SEARCH_ANATOMY' | translate}}"
      ></app-search-panel>
  </section>

  <!-- Content panel class="content-container"-->
  <section class="p-col-12 p-sm-7" >

    <section *ngIf="gallery">
      <h2>{{gallery.name}}</h2>
      <hr>
      <p *ngIf="!showSearchResult">{{gallery.description}}</p>
    </section>

    <div *ngIf="showSearchResult">
      <app-search-criteria-panel
        [searchCriteria]="searchCriteria"
      ></app-search-criteria-panel>

      <hr>

      <!-- Images -->
      <app-gallery
        [galleryId]="galleryId"
        [datasource]="searchResult.result"
        [tissuesData]="tissuesData[lang]"
        [showFilters]="true"
      ></app-gallery>
    </div>

    </section>
</section>
