<section>
  <button pButton [routerLink]="['../..']" label="{{'DIAGNOSE.BACK_TO_SEARCH' | translate}}" [icon]="'pi pi-angle-double-left'" class="p-color-dark"></button>
</section>
<hr>
<section *ngIf="diagnose">

    <app-diagnose
      [galleryId]="galleryId"
      [diagnose]="diagnose"
      [lang]="lang"
    ></app-diagnose>

</section>
