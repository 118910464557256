import {Component, Input, OnDestroy, OnInit } from '@angular/core';
import {Diagnose} from '../../../models/Diagnose';

@Component({
  selector: 'app-diagnose',
  templateUrl: './diagnose.component.html',
  styleUrls: ['./diagnose.component.scss']
})
export class DiagnoseComponent implements OnInit, OnDestroy {

  @Input() galleryId: number;
  @Input() diagnose: Diagnose;
  @Input() lang: string;

  constructor(
  ) { }

  async ngOnInit() {
  }

  ngOnDestroy() {
  }


}
