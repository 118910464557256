import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  // Käännetään annetut hakuehdot
  translateSearchCriteria(
    selectedSearchCriteria: string,
    transalationData: any): string {

    if (!selectedSearchCriteria) {
      return '';
    }
    const temp = selectedSearchCriteria.split(',');
    const translations = temp.map(translation => {
      let found = false;
      let idx = 0;
      let description = null;
      while ((!found) && (idx < transalationData.length)) {
        if (translation.toLowerCase().trim() === transalationData[idx].code.toLowerCase()) {
          description = transalationData[idx].description;
          found = true;
        }
        idx++;
      }
      return description || translation;
    });

    if ((translations) && (translations.length > 0)) {
      return translations.join(', ');
    } else {
      return ''
    }
  }

}
