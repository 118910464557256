<section>
  <h3>{{'SEARCH_CRITERIA.SEARCHRESULT' | translate}}</h3>
  <div *ngIf="searchCriteria.keyword">
    <label>{{'SEARCH_CRITERIA.KEYWORD' | translate}}:</label>
    <span>{{searchCriteria.keyword}}</span>
  </div>
  <div *ngIf="searchCriteria.age">
    <label>{{'SEARCH_CRITERIA.AGE' | translate}}:</label>
    <span>{{searchCriteria.age}}</span>
  </div>
  <div *ngIf="searchCriteria.sex">
    <label>{{'SEARCH_CRITERIA.SEX' | translate}}:</label>
    <span>{{searchCriteria.sex}}</span>
  </div>
  <div *ngIf="searchCriteria.location">
    <label>{{'SEARCH_CRITERIA.LOCATIONS' | translate}}:</label>
    <span>{{searchCriteria.location}}</span>
  </div>
  <div *ngIf="searchCriteria.tissue">
    <label>{{'SEARCH_CRITERIA.TISSUES' | translate}}:</label>
    <span>{{searchCriteria.tissue}}</span>
  </div>
  <div *ngIf="searchCriteria.types">
    <label>{{'SEARCH_CRITERIA.TYPES' | translate}}:</label>
    <span>{{searchCriteria.types}}</span>
  </div>

  <div>
    <label>{{'SEARCH_CRITERIA.RESULT_COUNT' | translate}}:</label>
    <span>{{searchCriteria.resultCount}}</span>
  </div>
</section>
