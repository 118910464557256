import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  loginUrl: string;
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.loginUrl = authService.getLoginUrl();
  }

  ngOnInit() {
    this.authService.removeAccessToken();
    this.authService.removeRefreshToken();
    window.location.href = this.authService.getLoginUrl();
  }

}
