<h4>{{title}}</h4>
<p-accordion *ngFor="let itemData of nestedItems">
  <p-accordionTab header="{{itemData.header}}">
    <div *ngFor="let item of itemData.words" >
      <p-checkbox
        [name]="'name'"
        [value]="item.code"
        [(ngModel)]="selectedItems"
        [label]="item.description"
      >
      </p-checkbox>
    </div>
  </p-accordionTab>
</p-accordion>
