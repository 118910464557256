import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';

import * as bodyParts from '../../../../assets/data/anatomy/bodyConfig.json';
import * as frontSpots from '../../../../assets/data/anatomy/frontSpots.json';
import * as backSpots from '../../../../assets/data/anatomy/backSpots.json';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {SearchCriteria} from '../../../models/SearchCriteria';
import {ImageService} from '../../../services/image-service';

declare var jQuery: any;
/*
      "outline": "#1b3a6b",
      "upColor": "#ff6600",
      "overColor": "#1b3a6b",
*/

@Component({
  selector: 'app-anatomy-body',
  templateUrl: './anatomy-body.component.html',
  styleUrls: ['./anatomy-body.component.scss']
})
export class AnatomyBodyComponent implements OnInit, AfterViewInit {
  private autoCompleteSearchCriteria: SearchCriteria;
  public autoCompleteData: string[];
  public lang: string;
  public keyword: string;
  public bodyPartsData: any = (bodyParts as any).default;
  public frontSpotsData: any = (frontSpots as any).default;
  public backSpotsData: any = (backSpots as any).default;
  @Input() galleryId: number;
  @Input() autoCompleteResultSize: number;
  @Input() searchTip: string;
  @Output() search: EventEmitter<any> = new EventEmitter();
  constructor(
    private translate: TranslateService,
    private imageService: ImageService,
    private zone: NgZone
  ) { }

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.lang = this.translate.currentLang;
      });

    this.autoCompleteSearchCriteria = new SearchCriteria();
    this.autoCompleteSearchCriteria.galleryId = this.galleryId;
    this.autoCompleteSearchCriteria.size = this.autoCompleteResultSize;
  }

  ngAfterViewInit(): void {
    this.initBody();
  }

  async autoCompleteSearch(event) {
    this.autoCompleteSearchCriteria.keyword = event.query.toLowerCase();
    this.autoCompleteData = await this.imageService.gallerySuggestion(this.autoCompleteSearchCriteria);
  }

  initBody() {
    this.zone.run(() => {
      jQuery(document).ready(() => {

        let pins_len = this.frontSpotsData.pins.length;
        if (pins_len > 0) {
          const xmlns = 'http://www.w3.org/2000/svg';
          const tsvg_obj = document.getElementById('anaspotsa');
          let svg_circle;
          for (let i = 0; i < pins_len; i++) {
            const pin = this.frontSpotsData.pins[i];
            svg_circle = document.createElementNS(xmlns, 'circle');
            svg_circle.setAttributeNS(null, 'cx', pin.pos_X);
            svg_circle.setAttributeNS(null, 'cy', pin.pos_Y);
            svg_circle.setAttributeNS(null, 'r', pin.size / 2);
            svg_circle.setAttributeNS(null, 'fill', pin.upColor);
            svg_circle.setAttributeNS(null, 'stroke', pin.outline);
            svg_circle.setAttributeNS(null, 'stroke-width', 1);
            svg_circle.setAttributeNS(null, 'id', 'anaspotsa_' + i);
            tsvg_obj.appendChild(svg_circle);
            this.anatomySpotsFrontAddEvent(i);
          }
        }

        pins_len = this.backSpotsData.pins.length;
        if (pins_len > 0) {
          const xmlns = 'http://www.w3.org/2000/svg';
          const tsvg_obj = document.getElementById('anaspotsb');
          let svg_circle;
          for (let i = 0; i < pins_len; i++) {
            const pin = this.backSpotsData.pins[i];
            svg_circle = document.createElementNS(xmlns, 'circle');
            svg_circle.setAttributeNS(null, 'cx', pin.pos_X);
            svg_circle.setAttributeNS(null, 'cy', pin.pos_Y);
            svg_circle.setAttributeNS(null, 'r', pin.size / 2);
            svg_circle.setAttributeNS(null, 'fill', pin.upColor);
            svg_circle.setAttributeNS(null, 'stroke', pin.outline);
            svg_circle.setAttributeNS(null, 'stroke-width', 1);
            svg_circle.setAttributeNS(null, 'id', 'anaspotsb_' + i);
            tsvg_obj.appendChild(svg_circle);
            this.anatomySpotsBackAddEvent(i);
          }
        }

        jQuery('#baseb').hide().animate({'opacity': '0'}, 100);
        jQuery('.gob').on('click', function() {
          jQuery('#basea').hide().animate({'opacity': '0'}, 100);
          jQuery('#baseb').show().animate({'opacity': '1'}, 100);
        });
        jQuery('.goa').on('click', function() {
          jQuery('#baseb').hide().animate({'opacity': '0'}, 100);
          jQuery('#basea').show().animate({'opacity': '1'}, 100);
        });

        this.addBodyPartEvents();
      });
    });

  }

  isTouchEnabled() {
    return (('ontouchstart' in window)
      || (navigator.maxTouchPoints > 0)
      || (navigator.msMaxTouchPoints > 0));
  }

  anatomySpotsFrontAddEvent(id) {
    const obj = jQuery('#anaspotsa_' + id);
    if (this.frontSpotsData.pins[id].enabled === true) {
      obj.attr({'cursor': 'pointer'});
      const spot = this.frontSpotsData.pins[id];
      const tip = jQuery('#anatip');

      obj.hover(() => {
        tip.show().html(spot.hover[this.lang]);
        obj.css({'fill': spot.overColor});
      }, () => {
        tip.hide();
        obj.css({'fill': spot.upColor});
      });

      obj.mouseup(() => {
        obj.css({'fill': spot.overColor});
        if (spot.target === 'new_window') {
          window.open(spot.url[this.lang]);
        } else if (spot.target === 'same_window') {
          window.parent.location.href = spot.url[this.lang];
        } else if (spot.target === 'modal') {
          jQuery(spot.url[this.lang]).modal('show');
        } else if (spot.target === 'route') {
          this.makeSearch(spot.url[this.lang])
        }
      });

      obj.mousemove((e) => {
        let x = e.pageX + 10, y = e.pageY + 15;
        const tipw = tip.outerWidth(), tiph = tip.outerHeight();
        x = (x + tipw > jQuery(document).scrollLeft() + jQuery(window).width())
          ? x - tipw - (20 * 2)
          : x ;
        y = (y + tiph > jQuery(document).scrollTop() + jQuery(window).height())
          ? jQuery(document).scrollTop() + jQuery(window).height() - tiph - 10
          : y ;
        tip.css({left: x, top: y});
      });

      if (this.isTouchEnabled()) {
        obj.on('touchstart', (e) => {
          const touch = e.originalEvent.touches[0];
          let x = touch.pageX + 10, y = touch.pageY + 15;
          const tipw = jQuery('#anatip').outerWidth(), tiph = jQuery('#anatip').outerHeight();
          x = (x + tipw > jQuery(document).scrollLeft() + jQuery(window).width())
            ? x - tipw - ( 20 * 2 )
            : x ;

          y = ( y + tiph > jQuery(document).scrollTop() + jQuery(window).height())
            ? jQuery(document).scrollTop() + jQuery(window).height() - tiph - 10
            : y ;

          jQuery('#anatip').show().html(spot.hover[this.lang]);
          jQuery('#anatip').css({left: x, top: y});
        });

        obj.on('touchend', () => {
          jQuery('#' + id).css({'fill': spot.upColor});
          if (spot.target === 'new_window') {
            window.open(spot.url[this.lang]);
          } else if (spot.target === 'same_window') {
            window.parent.location.href = spot.url[this.lang];
          } else if (spot.target === 'modal') {
            jQuery(spot.url[this.lang]).modal('show');
          } else if (spot.target === 'route') {
            this.makeSearch(spot.url[this.lang])
          }
        });
      }
    }
  }

  anatomySpotsBackAddEvent(id) {
    const obj = jQuery('#anaspotsb_' + id);

    if (this.backSpotsData.pins[id].enabled === true) {
      obj.attr({'cursor': 'pointer'});
      const spot = this.backSpotsData.pins[id];
      const tip = jQuery('#anatip');
      obj.hover(() => {
        tip.show().html(spot.hover[this.lang]);
        obj.css({'fill': spot.overColor});
      }, function () {
        tip.hide();
        obj.css({'fill': spot.upColor});
      });

      obj.mouseup(() => {
        obj.css({'fill': spot.overColor});
        if (spot.target === 'new_window') {
          window.open(spot.url[this.lang]);
        } else if (spot.target === 'same_window') {
          window.parent.location.href = spot.url[this.lang];
        } else if (spot.target === 'modal') {
          jQuery(spot.url[this.lang]).modal('show');
        } else if (spot.target === 'route') {
          this.makeSearch(spot.url[this.lang])
        }
      });

      obj.mousemove((e) => {
        let x = e.pageX + 10, y = e.pageY + 15;
        const tipw = tip.outerWidth(), tiph = tip.outerHeight();
        x = (x + tipw > jQuery(document).scrollLeft() + jQuery(window).width())
          ? x - tipw - (20 * 2)
          : x ;
        y = (y + tiph > jQuery(document).scrollTop() + jQuery(window).height())
          ? jQuery(document).scrollTop() + jQuery(window).height() - tiph - 10
          : y ;
        tip.css({left: x, top: y});
      });

      if (this.isTouchEnabled()) {
        obj.on('touchstart', (e) => {
          const touch = e.originalEvent.touches[0];
          let x = touch.pageX + 10, y = touch.pageY + 15;
          const tipw = jQuery('#anatip').outerWidth(), tiph = jQuery('#anatip').outerHeight();
          x = (x + tipw > jQuery(document).scrollLeft() + jQuery(window).width())
            ? x - tipw - ( 20 * 2 )
            : x ;

          y = ( y + tiph > jQuery(document).scrollTop() + jQuery(window).height())
            ? jQuery(document).scrollTop() + jQuery(window).height() - tiph - 10
            : y ;

          jQuery('#anatip').show().html(spot.hover[this.lang]);
          jQuery('#anatip').css({left: x, top: y});
        });

        obj.on('touchend', () => {
          jQuery('#' + id).css({'fill': spot.upColor});
          if (spot.target === 'new_window') {
            window.open(spot.url[this.lang]);
          } else if (spot.target === 'same_window') {
            window.parent.location.href = spot.url[this.lang];
          } else if (spot.target === 'modal') {
            jQuery(spot.url[this.lang]).modal('show');
          } else if (spot.target === 'route') {
            this.makeSearch(spot.url[this.lang])
          }
        });
      }
    }
  }


  addBodyPartEvents() {

    jQuery('path[id^=ana]').each( (i, e) => {
      const id =  jQuery(e).attr('id');
      const arr = id.split('');
      const _obj = jQuery('#' + id + ',' + arr.slice().join(''));
      _obj.attr({'fill': 'rgba(0, 0, 255, 0)', 'stroke': this.bodyPartsData.default.outlineColor});
      _obj.attr({'cursor': 'default'});
      const bodyPart = this.bodyPartsData[id];

      if (bodyPart.enabled === true) {
        _obj.attr({'cursor': 'pointer'});

        _obj.hover( () => {
          jQuery('#anatip').show().html(bodyPart.hover[this.lang]);
          _obj.css({'fill': 'rgba(132, 182, 255, 0.3)'});
        }, function () {
          jQuery('#anatip').hide();
          jQuery('#' + id).css({'fill': 'rgba(132, 182, 255, 0)'});
        });
        if (bodyPart.target !== 'none') {
          _obj.mousedown( () => {
            jQuery('#' + id).css({'fill': 'rgba(132, 182, 255, 0.7)'});
          });
        }

        _obj.mouseup( () => {
          jQuery('#' + id).css({'fill': 'rgba(132, 182, 255, 0.3)'});
          if (bodyPart.target === 'new_window') {
            window.open(bodyPart.url[this.lang]);
          } else if (bodyPart.target === 'same_window') {
            window.parent.location.href = bodyPart.url[this.lang];
          } else if (bodyPart.target === 'modal') {
            jQuery(bodyPart.url[this.lang]).modal('show');
          } else if (bodyPart.target === 'route') {
            this.makeSearch(bodyPart.url[this.lang])
          }
        });

        _obj.mousemove( (e) => {
          let x = e.pageX + 10, y = e.pageY + 15;
          const tipw = jQuery('#anatip').outerWidth();
          const tiph = jQuery('#anatip').outerHeight();
            x = (x + tipw > jQuery(document).scrollLeft() + jQuery(window).width())
              ? x - tipw - (20 * 2)
              : x ;
            y = (y + tiph > jQuery(document).scrollTop() + jQuery(window).height())
              ? jQuery(document).scrollTop() + jQuery(window).height() - tiph - 10
              : y ;
            jQuery('#anatip').css({left: x, top: y});
        });

        if (this.isTouchEnabled()) {
          _obj.on('touchstart', (e) => {
            const touch = e.originalEvent.touches[0];
            let x = touch.pageX + 10, y = touch.pageY + 15;
            const tipw = jQuery('#anatip').outerWidth(), tiph = jQuery('#anatip').outerHeight();
              x = (x + tipw > jQuery(document).scrollLeft() + jQuery(window).width())
                ? x - tipw - (20 * 2)
                : x ;
            y = (y + tiph > jQuery(document).scrollTop() + jQuery(window).height())
              ? jQuery(document).scrollTop() + jQuery(window).height() - tiph - 10
              : y ;
            jQuery('#' + id).css({'fill': 'rgba(132, 182, 255, 0.7)'});
            jQuery('#anatip').show().html(bodyPart.hover[this.lang]);
            jQuery('#anatip').css({left: x, top: y});
          });

          _obj.on('touchend',  () => {
            jQuery('#' + id).css({'fill': 'rgba(132, 182, 255, 0)'});
            if (bodyPart.target === 'new_window') {
              window.open(bodyPart.url[this.lang]);
            } else if (bodyPart.target === 'same_window') {
              window.parent.location.href = bodyPart.url[this.lang];
            } else if (bodyPart.target === 'modal') {
              jQuery(bodyPart.url[this.lang]).modal('show');
            } else if (bodyPart.target === 'route') {
              this.makeSearch(bodyPart.url[this.lang])
            }
          });
        }
      }
    });
  }

  makeSearch(keyword?: string) {
    const kw = keyword ? keyword : this.keyword;
    this.search.emit(kw);
  }

}
