import {Component, OnDestroy, OnInit} from '@angular/core';

import * as locations from '../../../assets/data/skinDisease/locations.json';
import * as types from '../../../assets/data/skinDisease/types.json';

import {ImageService} from '../../services/image-service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Gallery} from '../../models/Gallery';

@Component({
  selector: 'app-skin-disease-gallery',
  templateUrl: './skin-disease-gallery.component.html',
  styleUrls: ['./skin-disease-gallery.component.css']
})
export class SkinDiseaseGalleryComponent implements OnInit {

  public locationsData: any = (locations as any).default;
  public typeData: any = (types as any).default;

  public locationsList: Array<any>;
  public typesList: Array<any>;

  public gallery: Gallery;
  public lang: string;
  public galleryId: number;

  constructor(
    private translate: TranslateService,
    private imageService: ImageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.lang = this.translate.currentLang;
    this.typesList = [];
    this.locationsList = [];

    this.typesList.push(...this.typeData[this.lang]);
    this.locationsList.push(...this.locationsData[this.lang]);

  }

  ngOnInit() {
    this.galleryId = this.activatedRoute.snapshot.data['galleryId'];
    this.getGallery();
  }

  async getGallery() {
    this.gallery = await this.imageService.getGallery(this.galleryId, this.translate.currentLang);
  }

}
