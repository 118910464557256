import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  public error: string;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) { }

 async ngOnInit() {
    this.activatedRoute.params.subscribe( async params => {
      console.log('params', params.session);
      try {
        const org = await this.authService.checkSession(params.session);
        console.log('org', org);
        await this.router.navigate(['/']);
      } catch (err) {
        await this.router.navigate(['/logout']);
      }
    })
  }

}
