import { Injectable } from '@angular/core';
import {  HttpClient} from '@angular/common/http';
import { environment} from '../../environments/environment';
import {Gallery} from '../models/Gallery';
import {BehaviorSubject } from 'rxjs';
import {SearchCriteria} from '../models/SearchCriteria';
import {ImageSearchResult} from '../models/ImageSearchResult';

@Injectable()
export class ImageService {

  private galleries: Map<string, Array<Gallery>>;
  private galleriesSource = new BehaviorSubject<Array<Gallery>>(null);
  private imageSearchResultSource = new BehaviorSubject<ImageSearchResult>(null);
  private searchCriteriaSource = new BehaviorSubject<SearchCriteria>(null);

  private apiBaseUrl = environment.apiBaseUrl;
  private API_URL: string = this.apiBaseUrl + environment.IMAGE_KEYWORD_API;
  private _url: string = environment.SEARCH_API;

  private NEW_URL: string = this.apiBaseUrl + environment.SEARCH_API;
  private GENERAL_SEARCH_URL = this.apiBaseUrl + environment.GENERAL_SEARCH_API;
  private FRONT_URL = this.apiBaseUrl + environment.FRONT_PAGE_DESCRIPTION;

  public galleriesObservable$ = this.galleriesSource.asObservable();
  public imageSearchResultObservable$ = this.imageSearchResultSource.asObservable();
  public searchCriteriaObservable$ = this.searchCriteriaSource.asObservable();

  constructor(private http: HttpClient) {
    this.galleries = null;
  }

  getImage(galleryId, imageId) {
    const url = `${this.apiBaseUrl}`;
    return this.http.get(this.API_URL + '/' + galleryId + '/' + imageId);
  }

  getImageNew(key): any {
    return this.http.post<any>(this.NEW_URL, key);
  }
 getGeneralSearc(key): any {
    return this.http.post<any>(this.GENERAL_SEARCH_URL, key);
  }
  getAllImage(allImages: any): any  {
     return this.http.post<any>(this._url, allImages);
  }

  async searchImages(searchCriteria: SearchCriteria): Promise<ImageSearchResult> {
    const _searchResult =  await this.http.post<ImageSearchResult>(`${this.apiBaseUrl}gallery/search/`, searchCriteria).toPromise();
    if (_searchResult.info) {
      searchCriteria.resultCount = _searchResult.info.count;
    }
    this.setSearchCriteria(searchCriteria);
    this.setImageSearchResult(_searchResult);
    return _searchResult;
  }


  async gallerySuggestion(searchCriteria: SearchCriteria): Promise<string[]> {
    return await this.http.get<string[]>(
      `${this.apiBaseUrl}suggest/${searchCriteria.galleryId}/${searchCriteria.keyword}/${searchCriteria.size}`)
      .toPromise();
  }

  async getGalleries(lang): Promise<Gallery[]>  {

    if (!this.galleries) {
      this.galleries = new Map<string, Array<Gallery>>();
    }
    // Cache language specific galleries info. If info is not found from cache fetch it from server
    if (!this.galleries.has(lang)) {
      const galleryList = await this.http.get<Gallery[]>(this.FRONT_URL + lang).toPromise();
      this.galleries.set(lang, galleryList);
      this.galleries.set(lang, galleryList.map( gallery => {
        let route = '' + gallery.id;
        switch (gallery.id) {
          case 1:
            route = 'anatomy';
            break;
          case 2:
            route = 'skindisease';
            break;
          case 6:
            route = 'anatomy/fi';
            break;
          case 8:
            route = 'anatomy/en';
            break;
        }
        gallery.route = route;
        return gallery;
      }));
    }
    this.setGalleries(this.galleries.get(lang));
    return this.galleries.get(lang);
  }

  async getGallery(id: number, lang: string): Promise<Gallery> {

    let _galleries = new Array<Gallery>();
    let gallery: Gallery = null;

    // Fetch info for the galleries. Galleries might be already in cache...
    if (!this.galleries) {
      _galleries = await this.getGalleries(lang);
    } else if (!this.galleries.has(lang)) {
      _galleries = await this.getGalleries(lang);
    } else {
      _galleries = this.galleries.get(lang);
    }

    if (_galleries) {
      _galleries.forEach( item => {
        if (item.id === id) {
          gallery = item;
        }
      })
    }
    return gallery;

  }
  // Observebles to share galleries
  setGalleries(galleries: Array<Gallery>) {
    this.galleriesSource.next(galleries);
  }

  // Observebles to share the given search criteria
  setSearchCriteria(sc: SearchCriteria) {
    this.searchCriteriaSource.next(sc);
  }

  // Observebles to share the given search result
  setImageSearchResult(sr: ImageSearchResult) {
    this.imageSearchResultSource.next(sr);
  }


}
