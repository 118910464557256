import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-flat-check-box-list',
  templateUrl: './flat-check-box-list.component.html',
  styleUrls: ['./flat-check-box-list.component.scss']
})
export class FlatCheckBoxListComponent implements OnInit {

  @Input() title: string;
  @Input() name: string;
  @Input() items: any;

  public selectedItems: Array<string>;
  constructor() { }

  ngOnInit() {
  }

}
