import {Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import { Subscription } from 'rxjs';
import { ImageService } from '../../services/image-service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SearchCriteria } from '../../models/SearchCriteria';
import { ImageSearchResult } from '../../models/ImageSearchResult';
import { Gallery } from '../../models/Gallery';

import * as locations from '../../../assets/data/anatomy/locations.json';
import * as tissues from '../../../assets/data/anatomy/tissues.json';
import {UtilsService} from '../../services/utils.service';
declare var jQuery: any;
@Component({
  selector: 'app-anatomy-gallery',
  templateUrl: './anatomyGallery.component.html',
  styleUrls: ['./anatomyGallery.component.scss'],
})
export class AnatomyGalleryComponent implements OnInit, OnDestroy, AfterViewInit {
  private searchCriteria$: Subscription;
  private searchResult$: Subscription;
  private readonly locationList: Array<any>;
  private readonly tissueList: Array<any>;

  public locationsData: any = (locations as any).default;
  public tissuesData: any = (tissues as any).default;
  public galleryId = 1;
  public searchCriteria: SearchCriteria;
  public searchResult: ImageSearchResult = null;
  public gallery: Gallery;
  public showSearchResult = false;
  public lang: string;

  constructor(
    private translate: TranslateService,
    private imageService: ImageService,
    private utilsService: UtilsService,
    private routerOutlet: RouterOutlet,
    private router: ActivatedRoute) {

    this.lang = this.translate.currentLang;
    this.tissueList = [];
    this.locationList = [];

    this.tissueList.push(...this.tissuesData[this.lang]);

    const loc = this.locationsData[this.lang];
    for (let i = 0; i < loc.length; i++) {
      this.locationList.push(...loc[i].words);
    }
  }

  getGalleryId(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['galleryId'];
  }

  ngOnInit() {

    this.galleryId = this.getGalleryId(this.routerOutlet);
    this.getGallery();
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.lang = this.translate.currentLang;
      });

    // Clear former search result and criteria
    this.imageService.setImageSearchResult(null);
    this.imageService.setSearchCriteria(null);

    // Subscribe search criterias and search result from imageService
    this.searchCriteria$ = this.imageService.searchCriteriaObservable$
      .subscribe(
      result => {
        this.searchCriteria = result;
        if (this.searchCriteria) {
          this.searchCriteria.location = this.utilsService.translateSearchCriteria(this.searchCriteria.location, this.locationList);
          this.searchCriteria.tissue = this.utilsService.translateSearchCriteria(this.searchCriteria.tissue, this.tissueList);
        }
      }
    );

    this.searchResult$ = this.imageService.imageSearchResultObservable$
      .subscribe(
      result => {
        if (result) {
          this.showSearchResult = true;
        }

        this.searchResult = result;
      }
    );
  }

  ngOnDestroy() {
    if (this.searchCriteria$) {
      this.searchCriteria$.unsubscribe();
    }
    if (this.searchResult$) {
      this.searchResult$.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
  }

  async getGallery() {
    // Fetch gallery info
    this.gallery = await this.imageService.getGallery(this.galleryId, this.translate.currentLang);
  }


}

