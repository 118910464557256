import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable()
export class IhotautikuvastoService {
  private SKIN_DISEASE_API = environment.SKIN_DISEASE_API;
  private DIAGNOSE_API_URL = environment.DIAGNOSE_API;
  private AUTOFIL_URL = environment.AUTOFIL_URL;
  private DIAGNOSES_URL = environment.DIAGNOSES_API;
  constructor(private _http: HttpClient) { }

  getSkinDisease(key): any {
    return this._http.post<any>(this.SKIN_DISEASE_API, key);
  }
  getDiagnose(lang, key, galId): any {
    return this._http.get<any>(this.DIAGNOSE_API_URL + lang + '/' + key + '/' + galId);
  }
  getExactDiagnose(lang, group): any {
    return this._http.get<any>(this.DIAGNOSES_URL + lang  + '/' + group )
  }
  getAutofill(lang, key): any {
    return this._http.get<any>(this.AUTOFIL_URL + lang + '/' + key);
  }
}
