import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Diagnose} from '../../../models/Diagnose';
import {TranslateService} from '@ngx-translate/core';
import {DiagnoseService} from '../../../services/diagnose.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-skin-disease-diagnose',
  templateUrl: './diagnose.component.html',
  styleUrls: ['./diagnose.component.css']
})
export class DiagnoseComponent implements OnInit, OnDestroy {

  public diagnose: Diagnose;

  public galleryId: number;
  public diagnoseId: number;
  public lang: string;

  constructor(
    private translate: TranslateService,
    private diagnoseService: DiagnoseService,
    private activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.lang = this.translate.currentLang;
    this.galleryId = this.activatedRoute.parent.snapshot.data['galleryId'];
    this.activatedRoute.params.subscribe( async param => {
      this.diagnoseId = param.id;
      await this.getDiagnose();
    });

  }

  ngOnDestroy() {
  }

  async getDiagnose() {
    this.diagnose = await this.diagnoseService.getDiagnose(this.diagnoseId, this.lang, this.galleryId);
  }
}
