import { Routes } from '@angular/router';
import { AnatomyGalleryComponent} from './app/components/anatomyGallery/anatomyGallery.component';
import { GalleryHomeComponent } from './app/components/galleryHome/galleryHome.component';
import { RontgenAnatomiakuvastoComponent} from './app/components/rontgen-anatomiakuvasto/rontgen-anatomiakuvasto.component';
import { SkinDiseaseGalleryComponent } from './app/components/skin-disease-gallery/skin-disease-gallery.component';
import { ChildRtgGalleryComponent } from './app/components/child-rtg-gallery/child-rtg-gallery.component';
import { FractureGalleryComponent } from './app/components/fracture-gallery/fracture-gallery.component';
import { DiagnoseComponent } from './app/components/skin-disease-gallery/diagnose/diagnose.component';
import { SearchComponent } from './app/components/skin-disease-gallery/search/search.component';
import {AuthGuard} from './app/guards/auth.guard';
import {LogoutComponent} from './app/components/logout/logout.component';
import {LoginComponent} from './app/components/login/login.component';
import {AuthComponent} from './app/components/auth/auth.component';

export const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: GalleryHomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'anatomy',
        component: AnatomyGalleryComponent,
        data: {
          galleryId: 1
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'anatomy/en',
        component: AnatomyGalleryComponent,
        data: {
          galleryId: 8
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'anatomy/fi',
        component: AnatomyGalleryComponent,
        data: {
          galleryId: 6
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'skindisease',
        component: SkinDiseaseGalleryComponent,
        data: {
          galleryId: 2
        },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: SearchComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'diagnose/:id',
            component: DiagnoseComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'childrtg',
        component: ChildRtgGalleryComponent,
        data: {
          galleryId: 5
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'fracture',
        component: FractureGalleryComponent,
        data: {
          galleryId: 7
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'rtg',
        component: RontgenAnatomiakuvastoComponent,
        data: {
          galleryId: 3
        },
        canActivate: [AuthGuard]
      }
    ]
  },
  /*
  {
    path: 'graphics',
    component: GraphicalSearchComponent,
    canActivate: [AuthGuard]
  },
   */
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'auth/:session',
    component: AuthComponent
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  }




];
