<!-- Keyword with autocomplete -->
<div class="p-ml-4">
  <form (ngSubmit)="makeSearch()">
    <div class="p-inputgroup">
      <p-autoComplete
          [size]="40"
            name="keyword"
            [minLength]="3"
            [(ngModel)]="keyword"
            [suggestions]="autoCompleteData"
            (completeMethod)="autoCompleteSearch($event)"
            placeholder="{{searchTip}}"
            styleClass="p-autocomplete-list-item"
          >
      </p-autoComplete>
      <span class="p-inputgroup-addon" (click)="makeSearch()"><i class="pi pi-search"></i></span>
    </div>
  </form>
</div>
<!--
#8c8c8c
#6a6a6a
-->
<section>
  <span id="anatip"></span>
  <div id="anawrapper"><!-- V1.000.2.3 JS-->
    <div id="basea">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="640px" height="1300px" viewBox="0 0 640 1300" xml:space="preserve">
      <image overflow="visible" width="640" height="1300" xlink:href="assets/images/modela.png" ></image>
        <path id="ana1" class="head" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M320.837,186.442c18.022,0,31.313-10.149,40.574-21.812c0.106-0.87,0.194-1.727,0.254-2.578c2.393-5.448,4.557-14.769,4.328-18.038c-1.341-6.985,4.007-23.348,5.201-31.676c0.609-7.102,1.521-6.457,0-37.447c-1.391-28.415-27.27-42.043-44.906-44.111c-0.742-0.087-2.645-0.353-4.578-0.343c-2.231,0.012-4.472,0.307-4.472,0.307c-17.64,1.943-43.819,15.563-45.219,44.147c-1.519,30.991-0.608,30.346,0,37.447c1.314,8.161,6.088,25.191,5.201,31.676c-0.229,3.269,1.937,12.59,4.328,18.038c0.027,0.386,0.062,0.774,0.099,1.164C289.37,172.015,304.484,186.442,320.837,186.442z"/>
        <path id="ana2" class="eye-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M286,108.128c0-4.561,6.113-8.258,13.653-8.258s13.653,3.697,13.653,8.258c0,4.561-6.113,8.258-13.653,8.258S286,112.689,286,108.128z"/>
        <path id="ana3" class="eye-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M331.105,108.128c0-4.561,6.113-8.258,13.653-8.258s13.653,3.697,13.653,8.258c0,4.561-6.113,8.258-13.653,8.258S331.105,112.689,331.105,108.128z"/>
        <path id="ana4" class="ear-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M272.02,112.339c-2.733-4.197-10.182-5.172-9.06,7.102c1.02,11.147,4.125,18.038,5.149,22.88c1.025,4.842,5.694,7.869,9.111,1.695C278.108,137.53,273.333,120.5,272.02,112.339z"/>
        <path id="ana5" class="ear-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M365.993,144.015c3.418,6.174,8.087,3.147,9.112-1.695s4.131-11.732,5.15-22.88c1.121-12.274-6.326-11.298-9.062-7.102C370,120.667,364.652,137.03,365.993,144.015z"/>
        <path id="ana6" class="nose" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M309.684,132.473c-0.386,4.168,5.505,6.606,9.029,5.726c4.404,2.312,5.945,0.55,8.698-0.55c5.064,0.771,7.047-2.863,7.047-6.827s-3.854-5.946-4.294-10.791s-2.973-6.716-2.753-11.892c0.221-5.175-2.092-8.699-5.36-8.699c-4.589,0-5.54,6.937-5.209,9.359c0.33,2.422-1.872,9.359-2.203,12.112C314.309,123.664,310.235,126.527,309.684,132.473z"/>
        <path id="ana7" class="mouth" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M331.266,147.216c-1.987-2.398-5.489-1.455-6.938-0.661c-0.777,0.427-3.083,0.771-4.515-0.33c-1.431-1.101-5.395-0.11-6.496,1.431c-1.101,1.542-10.35,4.514-11.12,7.157c-0.771,2.643,7.597,2.862,9.689,3.633c2.092,0.771,3.964,3.303,11.561,3.303c7.598,0,9.659-2.455,11.671-2.863c2.013-0.408,7.157-0.991,7.488-3.083C342.937,153.712,334.458,151.069,331.266,147.216z"/>
        <path id="ana8" class="neck" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M266.685,241.972c8.068,4.268,16.904,7.401,25.315,8.028c18.974,1.416,17,13,28,13s9.001-11.93,30.333-11.667c12.615,0.156,22.538-2.672,30.415-7.429c7.563-4.567,13.232-10.917,17.594-18.108c-5.538-0.553-11.709-1.225-18.229-2.609c-8.331-1.768-21.411-5.021-22.067-18.038c-1.057-20.992,2.257-31.487,3.365-40.519c-9.262,11.663-22.552,21.812-40.574,21.812c-16.353,0-31.467-14.427-39.189-23.225c0.904,9.371,4.633,19.837,3.52,41.933c-0.655,13.017-13.736,16.27-22.065,18.038c-6.603,1.402-12.848,2.073-18.439,2.63C250.087,231.371,257.926,237.339,266.685,241.972z"/>
        <path id="ana9" class="chest" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M380.748,243.905c-7.877,4.757-17.8,7.584-30.415,7.429C329.001,251.07,331,263,320,263s-9.026-11.584-28-13c-8.411-0.627-17.247-3.761-25.315-8.028c-14.019,5.028-45.352,28.695-48.76,57.68c-3.159,26.862-5.385,64.375-5.385,82.013c0.501-0.483,3.711,23.606,4.293,26.508c2.224,11.086,4.81,26.158,5.979,35.07c7.915,2.919,22.262,4.677,49.325,0.033c14.672-2.518,29.37-6.291,50.176-6.291c20.808,0,36.987,4.048,50.011,6.345c24.949,4.4,39.347,3.443,47.961,0.838c1.103-8.762,3.794-24.514,6.098-35.995c0.582-2.902,3.791-26.991,4.293-26.508c0-9.665-3.009-63.478-5.317-78.257C418.38,258.729,393.531,249.341,380.748,243.905z"/>
        <path id="ana10" class="abdomen" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M372.324,443.33c-13.023-2.296-29.203-6.345-50.011-6.345c-20.807,0-35.504,3.772-50.176,6.291c-27.063,4.645-41.411,2.886-49.325-0.033c0.333,2.535,0.551,4.574,0.613,5.878c1.747,36.713-5.501,56.716-3.986,65.881c1.733,10.487-0.697,18-1.723,22.499c-0.934,4.097-0.694,11.341-1.777,21.235c14.3,6.13,45.298,14.237,108.334,14.237c54.847,0,86.184-5.991,103.316-11.6c-1.45-11.255-1.075-19.42-2.09-23.873c-1.025-4.499-3.457-12.012-1.723-22.499c1.514-9.165-5.734-29.167-3.986-65.881c0.054-1.141,0.229-2.848,0.494-4.953C411.671,446.773,397.273,447.729,372.324,443.33z"/>
        <path id="ana11" class="pelvis" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M215.939,558.736c-0.599,5.471-1.596,11.742-3.434,18.764c-1.455,5.561-2.941,11.166-4.4,16.919c27.779,11.477,56.715,40.896,78.421,66.231c14.974-8.983,52.012-8.424,68.577-0.537c20.898-25.097,51.143-55.601,79.787-66.566c-1.388-5.449-2.797-10.767-4.179-16.047c-1.549-5.918-2.5-11.301-3.121-16.126c-17.133,5.609-48.47,11.6-103.316,11.6C261.237,572.973,230.239,564.866,215.939,558.736z"/>
        <path id="ana12" class="pubis" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M286.526,660.65c10.604,12.377,19.483,23.777,25.659,31.634c1.191-4.118,4.986-8.95,9.422-8.95s8.846,4.874,9.424,8.95c4.063-6.415,12.679-18.487,24.072-32.171C338.538,652.227,301.5,651.667,286.526,660.65z"/>
        <path id="ana13" class="shoulder-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M217.925,299.652c3.408-28.985,34.741-52.652,48.76-57.68c-8.759-4.633-16.598-10.601-22.022-16.154c-5.201,0.518-9.838,0.938-13.655,1.752c-25.406,5.419-41.359,11.718-52.882,27.813c-6.288,8.784-11.292,35.117-12.286,61.34C173.881,303.407,212.608,316.578,217.925,299.652z"/>
        <path id="ana14" class="shoulder-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M380.748,243.905c12.783,5.437,37.632,14.825,44.61,59.502c5.317,16.926,43.976,0,52.017,13.315c-0.994-26.223-5.998-52.556-12.285-61.34c-11.523-16.095-27.477-22.395-52.883-27.813c-3.868-0.825-8.578-1.245-13.865-1.773C393.98,232.988,388.311,239.338,380.748,243.905z"/>
        <path id="ana15" class="arm-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M165.84,316.723c-0.239,6.311-5.614,24.655-6.313,27.984c-1.025,4.875-12.445,41.877-18.197,66.156c-0.171,0.723-0.372,1.537-0.598,2.424c4.755,7.91,14.327,13.659,22.747,16.739c8.958,3.277,23.567,3.012,33.538-1.542c7.411-18.707,15.438-46.735,15.524-46.819c0-17.638,2.227-55.15,5.385-82.013C212.608,316.578,173.881,303.407,165.84,316.723z"/>
        <path id="ana16" class="arm-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M479.659,430.025c8.453-3.092,18.066-8.874,22.802-16.833c-0.216-0.849-0.409-1.633-0.574-2.33c-5.752-24.278-17.172-61.281-18.197-66.156c-0.699-3.329-6.074-21.673-6.314-27.984c-8.041-13.315-46.699,3.611-52.017-13.315c2.309,14.779,5.317,68.593,5.317,78.257c0.086,0.083,8.125,28.154,15.541,46.86C456.186,433.038,470.729,433.292,479.659,430.025z"/>
        <path id="ana17" class="elbow-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M163.478,430.025c-8.42-3.08-17.992-8.829-22.747-16.739c-2.169,8.512-6.643,23.677-9.325,28.282c-1.189,2.043-5.438,11.777-9.146,21.161c-0.09,0.228-0.187,0.482-0.282,0.73c8.666-7.434,21.33-6.668,32.381-2.554c11.924,4.439,21.961,13.143,23.059,26.708c0.862-2.91,1.698-6.009,2.343-8.954c2.452-11.207,5.546-23.334,5.995-25.554c1.152-5.695,5.951-12.458,8.377-17.786c0.937-2.057,1.904-4.37,2.882-6.837C187.045,433.037,172.436,433.302,163.478,430.025z"/>
        <path id="ana18" class="elbow-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M488.777,460.906c11.101-4.133,23.831-4.888,32.499,2.654c-0.109-0.285-0.219-0.572-0.321-0.831c-3.707-9.384-7.957-19.118-9.146-21.161c-2.691-4.622-7.189-19.883-9.348-28.376c-4.735,7.959-14.349,13.741-22.802,16.833c-8.931,3.267-23.474,3.013-33.442-1.5c0.973,2.452,1.934,4.75,2.865,6.795c2.426,5.328,7.225,12.091,8.377,17.786c0.449,2.22,3.543,14.347,5.994,25.554c0.629,2.875,1.441,5.897,2.28,8.746C466.917,473.959,476.915,465.323,488.777,460.906z"/>
        <path id="ana19" class="forearm-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M154.359,460.906c-11.051-4.114-23.715-4.88-32.381,2.554c-2.312,6.017-6.092,17.717-8.944,27.584c-6.375,22.052-9.453,47.495-12.083,57.908c-2.013,7.969-3.732,15.479-6.365,22.881c-1.841,5.172-3.304,9.784-4.318,13.023c2.457,4.167,8.377,6.519,14.032,8.804c6.222,2.514,16.518,4.36,22.738,3.576c1.939-4.385,3.679-8.206,4.599-9.909c2.741-5.078,10.306-22.405,13.768-28.359c4.923-8.465,20.101-40.449,27.785-58.797c0.515-1.23,2.428-6.477,4.229-12.557C176.32,474.049,166.283,465.345,154.359,460.906z"/>
        <path id="ana20" class="forearm-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M538.837,593.661c5.694-2.301,11.657-4.669,14.083-8.89c-1.013-3.235-2.466-7.809-4.291-12.938c-2.633-7.402-4.352-14.912-6.365-22.881c-2.629-10.413-5.707-35.855-12.082-57.908c-2.837-9.811-6.592-21.437-8.905-27.484c-8.668-7.542-21.398-6.787-32.499-2.654c-11.862,4.417-21.86,13.053-23.044,26.5c1.819,6.17,3.771,11.521,4.292,12.766c7.686,18.349,22.863,50.332,27.785,58.797c3.463,5.954,11.027,23.281,13.768,28.359c0.921,1.705,2.662,5.53,4.603,9.919C522.41,598.004,532.643,596.164,538.837,593.661z"/>
        <path id="ana21" class="wrist-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M104.3,593.661c-5.655-2.285-11.575-4.638-14.032-8.804c-0.19,0.607-0.37,1.183-0.527,1.687c-1.653,5.29-4.736,13.29-10.655,17.527c-0.484,0.346-0.938,0.663-1.38,0.967c7.482,1.469,15.067,4.756,20.391,7.764c7.236,4.087,15.31,4.468,19.647,8.08c-0.087-1.374-0.08-2.354,0.228-2.925c0.443-0.826,3.046-6.908,5.916-13.517c1.046-2.408,2.126-4.882,3.151-7.202C120.817,598.021,110.521,596.175,104.3,593.661z"/>
        <path id="ana22" class="wrist-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M565.494,605.026c-0.437-0.301-0.886-0.614-1.363-0.956c-5.92-4.237-9.002-12.237-10.656-17.527c-0.165-0.527-0.354-1.133-0.555-1.773c-2.426,4.221-8.389,6.589-14.083,8.89c-6.194,2.503-16.427,4.343-22.656,3.586c1.024,2.316,2.103,4.787,3.147,7.192c2.871,6.609,5.473,12.691,5.916,13.517c0.303,0.562,0.314,1.519,0.231,2.857c4.357-3.548,12.375-3.95,19.565-8.012C550.38,609.785,557.992,606.489,565.494,605.026z"/>
        <path id="ana23" class="hand-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M77.705,605.037c-4.668,3.209-7.257,4.248-12.536,6.902c-5.78,2.905-14.077,7.689-16.013,9.142c-3.873,2.906-10.318,9.419-13.776,15.044c-2.311,3.758-8.38,9.208-8.38,12.315c0,5.227,4.149,5.416,6.429,5.174c2.277-0.242,5.77-2.937,9.186-7.779c3.418-4.842,10.557-7.668,13.846-7.384c2.959,0.256,3.416,3.21,2.276,5.752c-1.52,3.396-4.911,10.221-6.857,16.18c-1.949,5.968-4.253,12.687-5.922,19.275c-1.577,6.225-4.712,16.051-5.695,18.511c-1.132,2.831-2.382,8.956,1.986,10.806c3.074,1.302,7.289-1.331,9.68-6.9c2.393-5.568,3.164-10.984,4.759-15.826c1.595-4.843,4.492-13.701,5.63-17.696c1.14-3.994,2.279-5.447,3.189-5.084c0.911,0.363-0.228,2.542-1.024,5.568s-3.81,12.49-4.605,16.243c-0.798,3.753-4.759,18.127-6.012,23.212c-1.252,5.084-1.937,11.863,0.797,14.769c2.734,2.905,5.358,1.712,7.859-0.848c3.075-3.147,5.011-12.68,5.467-16.189c0.455-3.511,6.542-23.476,7.339-26.381s1.594-8.474,3.416-8.353s1.253,3.026,0.569,4.963c-0.683,1.938-3.125,9.585-3.581,13.58c-0.455,3.995-6.035,21.243-5.808,24.754s1.024,7.868,4.67,8.11c3.644,0.242,6.605-4.842,8.2-9.442c1.593-4.601,4.782-15.585,5.693-20.063c0.912-4.479,3.239-13.58,4.264-16.364c1.025-2.784,1.368-5.448,2.962-5.448c1.595,0,0.569,2.542-0.228,4.964c-0.798,2.421-3.125,10.916-3.922,14.063c-0.798,3.147-3.962,13.29-3.278,16.68s5.011,4.843,7.516,2.663c2.937-2.552,10.138-26.632,11.504-30.99s3.694-9.559,5.744-18.153c2.05-8.596,3.897-18.275,4.809-27.112c0.604-5.858,0.059-10.11-0.113-12.812c-4.338-3.611-12.411-3.992-19.647-8.08C92.771,609.793,85.187,606.506,77.705,605.037z"/>
        <path id="ana24" class="hand-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M607.836,636.125c-3.459-5.625-9.904-12.138-13.777-15.044c-1.936-1.453-10.232-6.237-16.012-9.142c-5.285-2.657-7.874-3.695-12.553-6.913c-7.502,1.463-15.114,4.759-20.453,7.775c-7.19,4.062-15.208,4.463-19.565,8.012c-0.167,2.698-0.726,6.974-0.116,12.88c0.91,8.837,2.758,18.517,4.809,27.112c2.049,8.595,4.377,13.795,5.744,18.153c1.365,4.358,8.566,28.438,11.504,30.99c2.504,2.18,6.832,0.727,7.516-2.663s-2.48-13.532-3.279-16.68c-0.797-3.147-3.123-11.643-3.922-14.063c-0.797-2.422-1.822-4.964-0.227-4.964c1.594,0,1.936,2.664,2.961,5.448s3.352,11.885,4.264,16.364c0.912,4.479,4.102,15.463,5.693,20.063c1.596,4.601,4.557,9.685,8.201,9.442s4.441-4.6,4.67-8.11c0.227-3.511-5.354-20.759-5.809-24.754s-2.898-11.643-3.58-13.58c-0.684-1.937-1.254-4.842,0.568-4.963s2.619,5.447,3.416,8.353s6.885,22.87,7.34,26.381c0.455,3.51,2.391,13.042,5.467,16.189c2.5,2.56,5.125,3.753,7.859,0.848c2.732-2.905,2.049-9.685,0.797-14.769c-1.254-5.085-5.215-19.459-6.012-23.212s-3.809-13.217-4.605-16.243s-1.936-5.205-1.025-5.568s2.051,1.09,3.189,5.084c1.139,3.995,4.035,12.854,5.631,17.696c1.594,4.842,2.365,10.258,4.758,15.826c2.391,5.569,6.605,8.202,9.68,6.9c4.369-1.85,3.119-7.975,1.986-10.806c-0.982-2.46-4.117-12.286-5.695-18.511c-1.668-6.589-3.973-13.308-5.922-19.275c-1.945-5.959-5.338-12.784-6.857-16.18c-1.139-2.542-0.682-5.497,2.277-5.752c3.289-0.284,10.428,2.542,13.846,7.384c3.416,4.843,6.908,7.537,9.186,7.779c2.279,0.242,6.428,0.053,6.428-5.174C616.215,645.333,610.146,639.883,607.836,636.125z"/>
        <path id="ana25" class="thigh-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M208.104,594.419c-9.71,38.291-18.229,83.266-8.547,167.073c3.397,29.41,11.836,68.395,16.123,91.01c30.556,11.933,46.821,40.016,77.685,26.125c1.834-8.279,4.947-27.063,6.443-38.188c1.519-11.299,7.138-53.993,9.187-64.888c2.05-10.896,4.557-42.35,3.418-52.761c-1.14-10.411-1.097-27.509-0.229-30.507c-6.175-7.856-15.054-19.257-25.659-31.634C264.819,635.316,235.883,605.896,208.104,594.419z"/>
        <path id="ana26" class="thigh-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M355.103,660.113c-11.394,13.684-20.009,25.756-24.072,32.171c0.438,3.09,0.91,20.096-0.229,30.507s1.367,41.865,3.418,52.761c2.049,10.895,7.668,53.589,9.186,64.888c1.52,11.299,4.709,30.506,6.529,38.576c0.026,0.118,0.054,0.23,0.081,0.348c30.309,12.335,46.576-16.168,77.639-27.491c4.322-22.705,12.638-61.242,16.004-90.38c9.756-84.443,1.032-129.464-8.769-167.945C406.245,604.513,376.001,635.017,355.103,660.113z"/>
        <path id="ana27" class="knee-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M215.681,852.502c1.508,7.958,2.504,13.895,2.583,16.667c0.304,10.652,3.265,37.608,3.265,48.746c0,6.604-0.403,15.255-1.157,24.128c16.096,17.077,44.536,7.221,64.708-4.912c-0.716-5.513-0.974-9.324-0.456-10.66c1.063-2.744,4.707-14.688,4.707-24.212c0-9.522,2.126-15.173,3.948-23.243c0.028-0.124,0.058-0.259,0.086-0.389C262.502,892.518,246.236,864.435,215.681,852.502z"/>
        <path id="ana28" class="knee-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M350.016,879.363c1.804,7.884,3.868,13.513,3.868,22.896c0,9.523,3.645,21.468,4.707,24.212c0.544,1.404,0.231,5.54-0.568,11.511c20.682,12.09,49.251,21.295,64.688,2.461c-0.668-8.308-1.022-16.32-1.022-22.527c0-11.138,2.961-38.094,3.264-48.746c0.081-2.846,1.127-9.021,2.703-17.297C396.592,863.195,380.324,891.698,350.016,879.363z"/>
        <path id="ana29" class="leg-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M220.372,942.043c-0.517,6.09-1.198,12.285-2.032,18c-2.05,14.043-3.835,45.457,2.1,86.456c3.839,26.521,8.834,68.119,9.518,79.982c0.683,11.864-0.228,17.433-0.912,21.549c-0.082,0.496-0.14,1.054-0.179,1.653c10.666-4.406,32.224-1.248,41.545,4.856c-0.37-4.986-1.157-11.275-2.303-14.741c-1.974-5.973-1.276-15.012,0.393-26.31c1.67-11.299,8.985-40.519,10.2-52.14s8.692-42.324,9.604-50.072c0.911-7.747,2.925-35.013,0.191-51.961c-1.402-8.692-2.663-16.382-3.417-22.186C264.908,949.264,236.468,959.12,220.372,942.043z"/>
        <path id="ana30" class="leg-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M358.022,937.981c-0.763,5.696-1.97,13.062-3.305,21.335c-2.732,16.948-0.719,44.214,0.191,51.961c0.912,7.748,8.389,38.451,9.604,50.072s8.532,40.841,10.202,52.14c1.668,11.298,2.365,20.337,0.393,26.31c-1.072,3.245-1.831,8.965-2.228,13.773c10.563-5.76,32.062-8.335,41.503-3.133c-0.03-0.89-0.097-1.712-0.213-2.409c-0.684-4.116-1.594-9.685-0.912-21.549c0.684-11.863,5.68-53.462,9.518-79.982c5.936-40.999,4.15-72.413,2.1-86.456c-0.907-6.217-1.635-13.002-2.165-19.601C407.273,959.276,378.704,950.071,358.022,937.981z"/>
        <path id="ana31" class="ankle-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M228.866,1149.684c-0.28,4.377,0.544,11.103,1.547,14.084c1.138,3.39,0.91,3.632-0.912,8.958c-1.822,5.327-2.251,12.429-6.503,19.531c-0.333,0.556-0.625,1.089-0.9,1.612c12.389,0.918,29.331,10.044,37.402,19.456c0.429-0.66,1.021-1.237,1.89-1.699c2.734-1.453,10.756-8.071,11.667-15.979c0.911-7.909,0.063-16.787-1.607-20.822c-1.67-4.034-1.367-11.459-0.911-13.073c0.191-0.678,0.141-3.604-0.127-7.211C261.09,1148.436,239.532,1145.277,228.866,1149.684z"/>
        <path id="ana32" class="ankle-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M372.88,1153.572c-0.333,4.042-0.411,7.441-0.202,8.179c0.455,1.614,0.758,9.039-0.913,13.073c-1.67,4.035-2.518,12.913-1.607,20.822c0.912,7.908,8.935,14.526,11.669,15.979c0.446,0.237,0.814,0.508,1.13,0.801c8.706-9.477,26.024-18.398,38.127-18.624c-0.266-0.502-0.548-1.014-0.866-1.546c-4.252-7.103-4.68-14.204-6.502-19.531c-1.822-5.326-2.051-5.568-0.912-8.958c0.945-2.815,1.731-8.965,1.58-13.328C404.941,1145.237,383.443,1147.813,372.88,1153.572z"/>
        <path id="ana33" class="foot-rt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M222.098,1193.869c-3.24,6.158-2.891,10.218-8.488,16.466c-6.075,6.779-11.672,15.022-14.882,21.145c-1.938,3.695-8.166,17.703-6.797,21.983c0.512,1.6,2.797,2.552,3.557,2.229c0.606,1.453,2.429,2.824,3.871,2.501c0.076,1.855,2.734,6.374,7.214,5.003c1.063,2.421,7.061,6.376,11.161,2.019c1.594,3.47,4.956,6.115,10.26,4.6c4.012-1.146,7.846-4.455,10.175-7.442c1.097-1.406,3.14-4.663,5.341-6.52c2.202-1.856,6.984-7.102,7.896-17.109c0.911-10.007,5.733-17.594,6.492-20.337c0.518-1.871,0.684-3.666,1.602-5.08C251.428,1203.913,234.486,1194.787,222.098,1193.869z"/>
        <path id="ana34" class="foot-lt" fill="transparent" stroke="#1b3a6b" vector-effect="non-scaling-stroke" d="M382.956,1212.427c1.617,1.503,1.727,3.683,2.362,5.979c0.758,2.743,5.58,10.33,6.492,20.337c0.912,10.008,5.693,15.253,7.896,17.109c2.201,1.856,4.244,5.113,5.34,6.52c2.33,2.987,6.164,6.296,10.176,7.442c5.303,1.516,8.666-1.13,10.26-4.6c4.1,4.357,10.098,0.402,11.16-2.019c4.48,1.371,7.139-3.147,7.215-5.003c1.441,0.323,3.264-1.048,3.871-2.501c0.76,0.323,3.045-0.629,3.557-2.229c1.369-4.28-4.859-18.288-6.797-21.983c-3.211-6.122-8.807-14.365-14.883-21.145c-5.617-6.271-5.244-10.337-8.522-16.532C408.98,1194.028,391.662,1202.95,382.956,1212.427z"/>
        <rect class="gob" x="488" y="1023" fill="#1b3a6b" stroke="#1b3a6b" vector-effect="non-scaling-stroke" width="137" height="245"/>
        <g id="anaspotsa"></g>
    </svg>
    </div><!-- basea-->
    <div id="baseb">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="640px" height="1300px" viewBox="0 0 640 1300" xml:space="preserve">
      <image overflow="visible" width="640" height="1300" xlink:href="assets/images/modelb.png" ></image>
        <path id="ana35" class="head-back" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M363.982,164.815c1.877-3.933,3.869-8.207,4.502-10.145c13.245-40.523,12.265-67.047,8.454-87.062c-5.142-27.012-22.793-39.597-46.53-42.708c-1.803-0.236-11.263-0.781-18.522,0.139c-23.228,2.943-40.359,15.979-45.42,42.569c-3.81,20.015-4.791,46.538,8.453,87.062c0.651,1.991,2.737,6.45,4.658,10.47C299.076,176.319,345.401,176.082,363.982,164.815z"/>
        <path id="ana36" class="neck-back" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M388.188,217.857c-19.33-4.688-32.611-13.146-28.026-44.898c0.052-0.356,1.874-4.067,3.82-8.144c-18.581,11.267-64.906,11.504-84.405,0.325c1.887,3.948,3.614,7.473,3.664,7.819c4.726,32.725-9.525,40.706-29.819,45.319C279.703,221.549,358.538,221.497,388.188,217.857z"/>
        <path id="ana37" class="back" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M430.535,267.507c-1.075-6.62-7.212-29.921-22.797-45.759c-0.009-0.002-0.018-0.003-0.025-0.005c-6.859-1.324-13.486-2.421-19.524-3.886c-29.65,3.64-108.486,3.692-134.766,0.42c-4.465,1.015-9.222,1.868-14.134,2.784c-18.326,15.355-25.09,40.759-25.182,52.365c-0.031,3.886,0.25,7.493,0.702,10.839c1.435,10.64,4.64,18.582,5.416,23.909c1.021,7.003,4.648,17.43,2.535,41.969c0.932,0.071,2.381,32.044,1.987,93.943c13.309,3.765,41.081,6.8,71.909,7.423c9.199,0.186,18.669-1.671,28.108-1.713c9.504-0.042,18.974,1.729,28.101,1.458c28.386-0.845,53.431-3.886,65.793-7.447c-0.389-61.715,1.059-93.593,1.988-93.665c-0.585-10.283,0.236-23.752,1.985-35.536c1.108-7.469,5.13-17.343,7.203-28.041C431.03,280.388,431.579,273.934,430.535,267.507z"/>
        <path id="ana38" class="loin" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M324.763,449.797c-9.439,0.042-18.909,1.899-28.108,1.713c-30.828-0.623-58.6-3.658-71.909-7.423c-0.049,7.696-0.126,15.852-0.237,24.469c-0.18,14.071-7.837,49.844-15.545,85.232c13.711-4.235,43.823-7.352,65.239-7.352c29.208,0,30.692,6.217,50.967,6.217c18.791,0,25.714-6.074,50.962-6.149c20.545-0.061,46.824,4.468,58.761,9.361c-7.858-36.056-15.813-72.963-15.998-87.31c-0.112-8.722-0.189-16.97-0.238-24.748c-12.362,3.561-37.407,6.602-65.793,7.447C343.736,451.527,334.267,449.755,324.763,449.797z"/>
        <path id="ana39" class="buttocks" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M325.17,552.654c-20.275,0-21.76-6.217-50.967-6.217c-21.417,0-51.528,3.117-65.239,7.352c-0.933,4.284-1.867,8.563-2.789,12.796c-9.636,44.27-11.48,85.472-10.381,120.157c31.751,24.883,79.135,34.932,115.548,8.113c1.5-2.367,5.833-6.841,6.75-9.044c0.916-2.205-0.084-4.312,3.083-4.312h1.055c3.166,0,2.166,2.106,3.083,4.312c0.917,2.203,5.25,6.685,6.75,9.052c36.735,27.055,84.102,16.592,115.565-8.749c1.054-34.545-0.825-75.521-10.399-119.505c-0.772-3.549-1.554-7.142-2.336-10.731c-11.937-4.894-38.216-9.428-58.761-9.367C350.884,546.586,343.961,552.654,325.17,552.654z"/>
        <path id="ana40" class="shoulder-back-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M429.833,286.568c15.367,22.622,32.571,23.62,51.775,42.585c-3.22-15.051-2.647-25.258-12.714-63.019c-9.692-36.362-34.91-39.321-61.156-44.386c15.585,15.837,21.722,39.139,22.797,45.759C431.579,273.934,431.03,280.388,429.833,286.568z"/>
        <path id="ana41" class="shoulder-back-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M214.808,284.267c-0.452-3.347-0.732-6.954-0.702-10.839c0.092-11.606,6.856-37.01,25.182-52.365c-1.191,0.222-2.391,0.448-3.598,0.681c-26.256,5.067-51.485,8.017-61.182,44.391c-9.991,37.479-9.501,47.813-12.641,62.681C181.563,309.557,199.151,308.998,214.808,284.267z"/>
        <path id="ana42" class="arm-back-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M487.415,441.132c9.105-3.596,20.243-8.124,22.772-23.852c-8.242-31.393-21.043-60.122-26.582-80.024c-0.818-2.939-1.459-5.587-1.997-8.104c-19.204-18.965-36.408-19.963-51.775-42.585c-2.073,10.698-6.095,20.572-7.203,28.041c-1.749,11.783-2.57,25.253-1.985,35.536c0.33-0.024,5.281,9.56,10.08,23.544c7.488,21.825,21.552,46.993,28.988,63.788C467.917,443.291,478.55,444.632,487.415,441.132z"/>
        <path id="ana43" class="arm-back-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M220.224,308.175c-0.776-5.327-3.98-13.269-5.416-23.909c-15.657,24.731-33.245,25.29-52.94,44.548c-0.553,2.617-1.216,5.371-2.07,8.442c-5.575,20.033-18.507,49.009-26.744,80.643c2.72,15.228,13.682,19.685,22.667,23.233c9.006,3.556,19.837,2.115,28.092-3.936c7.469-16.794,21.42-41.806,28.866-63.508c4.798-13.984,9.75-23.568,10.079-23.544C224.872,325.605,221.244,315.179,220.224,308.175z"/>
        <path id="ana44" class="elbow-back-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M497.719,475.093c18.565-5.662,28.972-3.196,34.893,0.79c-3.938-12.259-8.37-21.675-9.467-23.488c-1.57-2.598-7.783-12.436-10-22.772c-0.891-4.15-1.887-8.267-2.957-12.341c-2.529,15.728-13.667,20.256-22.772,23.852c-8.865,3.5-19.498,2.158-27.702-3.656c2.389,5.393,4.099,9.929,4.682,13.205c0.893,5.007,2.936,12.964,4.25,17.875c2.086,7.792,4.49,15.338,7.111,22.626C480.406,482.826,492.128,476.797,497.719,475.093z"/>
        <path id="ana45" class="elbow-back-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M155.722,441.132c-8.985-3.548-19.948-8.005-22.667-23.233c-1.008,3.872-1.95,7.782-2.795,11.723c-2.217,10.337-8.429,20.175-10,22.772c-1.09,1.802-5.477,11.117-9.398,23.271c5.976-3.855,16.333-6.131,34.557-0.573c5.657,1.725,17.588,7.877,22.122,16.389c2.663-7.38,5.105-15.026,7.219-22.925c1.314-4.911,3.358-12.868,4.25-17.875c0.593-3.333,2.35-7.967,4.805-13.485C175.559,443.248,164.728,444.688,155.722,441.132z"/>
        <path id="ana46" class="forearm-back-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M551.09,568.16c-6.176-23.555-11.148-61.246-13.945-75.117c-1.21-5.999-2.825-11.845-4.533-17.161c-5.921-3.985-16.327-6.451-34.893-0.79c-5.591,1.705-17.313,7.733-21.963,16.09c13.126,36.501,31.699,66.511,43.066,88.361c5.941-0.519,14.279-2.663,19.337-4.959C543.161,572.313,548.094,570.374,551.09,568.16z"/>
        <path id="ana47" class="forearm-back-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M145.418,475.093c-18.224-5.558-28.582-3.282-34.557,0.573c-1.733,5.373-3.376,11.297-4.602,17.377c-2.804,13.902-7.792,51.735-13.987,75.279c3.014,2.147,7.827,4.047,12.706,6.263c5.131,2.33,13.637,4.501,19.593,4.98c11.335-21.792,29.846-51.705,42.97-88.083C163.006,482.97,151.075,476.817,145.418,475.093z"/>
        <path id="ana48" class="wrist-back-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M554.895,580.215c-1.311-3.261-2.581-7.386-3.805-12.055c-2.996,2.214-7.929,4.153-12.931,6.425c-5.058,2.296-13.396,4.44-19.337,4.959c0.966,1.855,1.883,3.657,2.736,5.394c0.316,0.643,1.586,4.91,3.336,9.48c1.04,2.716,1.931,6.842,2.501,10.739c8.449-5.843,24.948-13.1,36.05-14.605C560.181,588.695,557.113,585.735,554.895,580.215z"/>
        <path id="ana49" class="wrist-back-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M92.272,568.322c-1.211,4.603-2.467,8.669-3.763,11.893c-2.231,5.551-5.32,8.513-8.604,10.368c11.204,1.582,27.757,8.905,36.08,14.738c0.57-3.944,1.47-8.149,2.525-10.903c1.75-4.57,3.02-8.838,3.336-9.48c0.851-1.73,1.764-3.524,2.726-5.373c-5.956-0.479-14.461-2.65-19.593-4.98C100.099,572.369,95.286,570.469,92.272,568.322z"/>
        <path id="ana50" class="hand-back-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M603.395,612.537c-2.166-1.795-3.25-4.621-5.166-5.438c-4.293-1.83-5.832-4.438-10.166-6.561c-2.846-1.393-7.707-4.018-16-6.692c-2.734-0.882-5.751-1.665-8.617-3.295c-11.102,1.505-27.601,8.762-36.05,14.605c0.39,2.661,0.632,5.215,0.665,7.136c0.084,4.734,3.75,17.631,4.25,21.957s0.502,11.508,3.002,17.222s1.5,10.856,3.332,13.795c1.834,2.938,2.25,8.122,5.168,13.101c2.916,4.978,5.166,5.469,6.416,2.121c1.25-3.347,0-9.999-0.834-11.713c-0.832-1.715-1.916-7.427-2.334-10.202c-0.416-2.776-1.582-7.428-0.75-9.632c0.834-2.203,3.168,2.367,3.334,4.408c0.168,2.04,1.416,12.08,2.084,15.998c0.666,3.918,4.25,14.793,5.416,19.691c1.168,4.897,5.5,8.407,7.834,6.938c2.332-1.47,0.75-6.694,0.582-8.898c-0.166-2.203-2.416-13.242-2.582-16.263c-0.168-3.021-1.666-15.427-1.916-17.141c-0.25-1.715,2.25-1.551,2.75,0.571s2.082,9.387,2.832,12.733c0.75,3.346,2.916,15.1,3.666,21.222c0.75,6.121,4.918,10.04,7.5,10.202c2.584,0.164,3.084-6.529,3.168-8.979c0.082-2.448-1-12.242-1.5-15.018s-4.25-24.324-3.168-24.324c1.084,0,3.584,14.448,4.834,20.814c1.25,6.367,2.75,10.733,6.668,10.814c3.916,0.081,3.25-2.734,3.416-10.488s-0.75-13.631-2.334-24.731c-1.582-11.101-4.332-17.55-5.416-21.548c-1.084-4-3.166-10.529-2.084-12.326c1.084-1.795,7.5,1.633,11.334,5.061c3.832,3.429,10.834,4.408,13.25,2.449S605.563,614.334,603.395,612.537z"/>
        <path id="ana51" class="hand-back-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M71.342,593.847c-8.294,2.675-13.155,5.3-16,6.692c-4.334,2.123-5.873,4.73-10.167,6.561c-1.916,0.816-2.999,3.643-5.166,5.438c-2.167,1.797-4.999,5.633-2.583,7.592s9.417,0.979,13.25-2.449c3.833-3.428,10.25-6.855,11.333-5.061c1.083,1.797-0.999,8.326-2.083,12.326c-1.084,3.998-3.834,10.447-5.417,21.548s-2.499,16.978-2.333,24.731s-0.501,10.569,3.416,10.488s5.417-4.447,6.667-10.814c1.25-6.366,3.751-20.814,4.834-20.814s-2.667,21.549-3.167,24.324s-1.583,12.569-1.5,15.018c0.083,2.449,0.584,9.143,3.167,8.979c2.583-0.162,6.75-4.081,7.5-10.202c0.75-6.122,2.916-17.876,3.666-21.222c0.75-3.347,2.333-10.611,2.833-12.733s3-2.286,2.75-0.571c-0.25,1.714-1.749,14.12-1.916,17.141s-2.416,14.06-2.583,16.263c-0.167,2.204-1.75,7.429,0.583,8.898c2.333,1.469,6.666-2.041,7.833-6.938c1.167-4.898,4.75-15.773,5.417-19.691s1.916-13.958,2.083-15.998c0.167-2.041,2.501-6.611,3.334-4.408c0.833,2.204-0.333,6.855-0.75,9.632c-0.417,2.775-1.501,8.487-2.334,10.202c-0.833,1.714-2.083,8.366-0.833,11.713c1.25,3.348,3.499,2.856,6.416-2.121c2.917-4.979,3.334-10.162,5.167-13.101s0.833-8.081,3.333-13.795s2.501-12.896,3.001-17.222s4.167-17.223,4.25-21.957c0.033-1.881,0.266-4.37,0.641-6.972c-8.322-5.833-24.876-13.156-36.08-14.738C77.055,592.192,74.059,592.97,71.342,593.847z"/>
        <path id="ana52" class="hamstring-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M332.063,694.768c1.5,2.368,0.583,1.759,0.5,4.288c-0.083,2.53-0.833,6.715-0.333,16.02s0.766,30.549,2.166,45.679c1.482,16.006,5.334,96.563,5.166,106.031c-0.152,8.71,0.681,39.919,1.465,53.636c8.191,7.633,21.934,10.134,35.611,9.274c12.107-0.759,25.751-3.677,37.552-16.11c2.414-14.97,6.117-35.186,6.873-43.201c1-10.61,4.5-30.527,6.5-43.75s11.68-50.217,13.5-64.983c2.247-18.234,5.61-44.279,6.565-75.584C416.164,711.407,368.798,721.823,332.063,694.768z"/>
        <path id="ana53" class="hamstring-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M195.794,686.742c0.982,31.005,4.316,56.804,6.548,74.908c1.82,14.767,11.5,51.761,13.5,64.983s5.5,33.14,6.5,43.75c0.761,8.076,4.515,28.538,6.926,43.541c11.727,12.149,25.234,15.019,37.231,15.771c13.836,0.869,27.739-1.699,35.895-9.54c0.779-13.854,1.602-44.712,1.449-53.364c-0.168-9.469,3.685-90.021,5.166-106.026c1.4-15.13,1.666-36.363,2.166-45.668s-0.25-13.467-0.333-15.997c-0.083-2.529-1-1.876,0.5-4.244C274.929,721.674,227.545,711.625,195.794,686.742z"/>
        <path id="ana54" class="knee-back-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M376.638,929.695c-13.678,0.859-27.42-1.642-35.611-9.274c0.068,1.196,0.137,2.273,0.203,3.177c0.833,11.264,5.832,23.673,5.832,39.834c0,14.874-1.417,25.478-4.76,41.962c9.32-3.769,23.338-6.845,34.63-7.719c17.942-1.389,30.25,3.922,38.331,12.163c0.042-4.237,0.087-8.393,0.132-12.123c0.168-13.713-2.666-28.078-4.166-38.199s-0.334-20.732,1.166-33.63c0.367-3.154,1.014-7.454,1.795-12.301C402.389,926.019,388.745,928.937,376.638,929.695z"/>
        <path id="ana55" class="knee-back-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M266.499,929.695c-11.997-0.752-25.504-3.621-37.231-15.771c0.756,4.706,1.382,8.882,1.741,11.961c1.5,12.897,2.666,23.509,1.166,33.63s-4.333,24.486-4.166,38.199c0.044,3.652,0.089,7.711,0.13,11.856c8.084-8.089,20.318-13.27,38.066-11.896c11.41,0.883,25.604,4.015,34.921,7.837c-3.361-16.553-4.784-27.17-4.784-42.079c0-16.161,4.999-28.568,5.832-39.832c0.072-0.972,0.145-2.139,0.219-3.445C294.238,927.996,280.335,930.564,266.499,929.695z"/>
        <path id="ana56" class="calf-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M376.932,997.675c-11.292,0.874-25.31,3.95-34.63,7.719c-0.29,1.425-0.588,2.879-0.906,4.399c-4,19.1-2.166,38.689-0.166,52.729c2,14.039,3,37.709,6.166,62.195c2.441,18.882,3.986,33.97,4.186,47.077c5.214,4.751,12.417,7.217,19.32,6.925c6.712-0.284,14.299-1.613,19.982-4.774c0.004-0.03,0.008-0.061,0.012-0.091c2.166-16.814,10.668-54.523,14.667-76.724c4-22.2,9.5-49.627,9.5-57.952c0-6.061,0.088-18.002,0.2-29.341C407.182,1001.597,394.874,996.286,376.932,997.675z"/>
        <path id="ana57" class="calf-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M228.139,1009.571c0.114,11.418,0.203,23.499,0.203,29.607c0,8.325,5.5,35.752,9.5,57.952s12.501,59.909,14.667,76.724c0.011,0.084,0.021,0.168,0.032,0.252c5.651,3.047,13.096,4.333,19.695,4.613c7.021,0.297,14.358-2.256,19.591-7.167c0.215-13.051,1.756-28.07,4.183-46.835c3.166-24.486,4.166-48.156,6.166-62.195c2-14.04,3.834-33.629-0.166-52.729c-0.31-1.479-0.601-2.895-0.882-4.282c-9.318-3.822-23.511-6.954-34.921-7.837C248.457,996.302,236.223,1001.482,228.139,1009.571z"/>
        <path id="ana58" class="ankle-back-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M351.581,1171.795c0.06,3.891,0.005,7.615-0.186,11.202c-0.408,7.666-3.25,14.691-5.416,21.793c-2.167,7.101-0.167,12.486,1.166,16.323c1.334,3.837,1.584,6.856,0.584,11.264c-1,4.408,1.25,13.795,1.416,18.692c0.021,0.633,0.035,1.34,0.055,2.101c10.814-7.212,35.872-8.548,37.23,15.64c1.061-1.176,1.966-2.421,2.721-3.599c0.479-6.917,0.656-16.398,0.412-19.848c-0.706-9.989-1.414-16.887,0.499-25.313c-0.17-0.153-0.339-0.311-0.499-0.485c-1.5-1.633-1-7.184-1.334-10.448c-0.331-3.26,0.497-18.392,2.654-35.171c-5.684,3.161-13.271,4.49-19.982,4.774C363.998,1179.012,356.795,1176.546,351.581,1171.795z"/>
        <path id="ana59" class="ankle-back-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M272.235,1178.72c-6.599-0.28-14.044-1.566-19.695-4.613c2.142,16.715,2.966,31.761,2.634,35.01c-0.333,3.265,0.167,8.815-1.333,10.448c-0.23,0.25-0.473,0.47-0.721,0.679c1.859,8.34,1.154,15.206,0.453,25.12c-0.239,3.369-0.075,12.497,0.379,19.365c0.756,1.225,1.676,2.543,2.772,3.799c1.551-24.09,26.851-22.516,37.473-15.178c0.023-0.828,0.038-1.599,0.061-2.28c0.166-4.897,2.416-14.284,1.416-18.692c-1-4.407-0.749-7.427,0.584-11.264s3.333-9.223,1.166-16.323c-2.166-7.102-5.008-14.127-5.416-21.793c-0.195-3.662-0.248-7.465-0.183-11.444C286.593,1176.464,279.257,1179.017,272.235,1178.72z"/>
        <path id="ana60" class="sole-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M349.2,1253.17c0.136,5.123,0.587,12.814,6.029,18.712c6.25,6.774,20.084,3.756,25.834,1.062c2.108-0.987,3.889-2.492,5.367-4.134C385.072,1244.622,360.015,1245.958,349.2,1253.17z"/>
        <path id="ana61" class="sole-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M256.725,1268.527c1.527,1.75,3.388,3.372,5.617,4.416c5.75,2.694,19.583,5.713,25.833-1.062c5.38-5.831,5.882-13.409,6.023-18.532C283.576,1246.012,258.276,1244.438,256.725,1268.527z"/>
        <path id="ana62" class="foot-back-rt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M389.563,1245.363c0.244,3.449,0.066,12.931-0.412,19.848c1.063-1.661,1.834-3.187,2.328-4.184c1.334-2.694,9.5-11.101,12.749-14.61c3.25-3.51,2.5-4.979,2.5-6.938s2-1.959,2.5-5.224s-1.834-4.733-2.916-5.469c-1.084-0.734-4.084-0.734-4.249-1.387c-0.166-0.654-1.25-1.879-3.168-2.857c-1.916-0.979-4,0.652-4.916-1.062c-0.819-1.53-2.498-2.153-3.917-3.431C388.149,1228.477,388.857,1235.374,389.563,1245.363z"/>
        <path id="ana63" class="foot-back-lt" fill="transparent" stroke="#8c8c8c" vector-effect="non-scaling-stroke" d="M253.573,1245.363c0.701-9.914,1.407-16.78-0.453-25.12c-1.368,1.15-2.918,1.786-3.695,3.237c-0.917,1.714-3,0.082-4.917,1.062c-1.917,0.979-3.001,2.203-3.167,2.857c-0.166,0.652-3.167,0.652-4.25,1.387c-1.083,0.735-3.416,2.204-2.916,5.469s2.5,3.265,2.5,5.224s-0.751,3.429,2.499,6.938s11.416,11.916,12.75,14.61c0.446,0.899,1.12,2.23,2.028,3.701C253.499,1257.86,253.334,1248.732,253.573,1245.363z"/>
        <rect class="goa" x="488" y="1023" fill="#72B8FC" stroke="#6a6a6a" vector-effect="non-scaling-stroke" width="137" height="245"/>
        <g id="anaspotsb"></g>
    </svg>
    </div><!-- baseb-->
  </div>
  <div class="clear"></div>
</section>

