import {Component, Input, AfterViewInit, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, SimpleChange} from '@angular/core';
import { HostListener } from '@angular/core';
import {Image} from '../../../models/Image';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Lightbox, LightboxConfig} from 'ngx-lightbox';
import {ImageFilter} from '../../../models/ImageFilter';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() datasource: Array<Image>;
  @Input() galleryId: number;
  @Input() showFilters: boolean;
  @Input() tissuesData: any;

  private firstFiltering = true;
  private allImages = [];
  public images = [];
  public tissues: Set<ImageFilter>;
  public tissueToggleLabel: string;
  public allFilters = true;
  lang: string;
  imagePath = 'https://www.terveysportti.fi/sovellukset/dg/kuvat/';
  thumbPath = 'https://www.terveysportti.fi/sovellukset/dg/kuvat/thumb100/';

  constructor(
    private translate: TranslateService,
    private lightboxConfig: LightboxConfig,
    private lightbox: Lightbox
  ) {
    this.lang = this.translate.currentLang;
    lightboxConfig.fitImageInViewPort = true;
    lightboxConfig.showImageNumberLabel = true;
    lightboxConfig.disableKeyboardNav = false;
    lightboxConfig.disableScrolling = true;
  }

  ngOnInit() {
    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.lang = this.translate.currentLang;
        this.getTranslations()
      });
      this.getTranslations()
    // this.getScreenSize();
  }

  ngAfterViewInit() {
    // Fine tune image path for certain galleries...
    if (this.galleryId === 8) {
      this.imagePath = `${this.imagePath}eng/`;
    } else if (this.galleryId === 6) {
      this.imagePath = `${this.imagePath}fi/`;
    }
    this.updateImages();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.datasource) {
      if (!changes.datasource.firstChange) {
        this.firstFiltering = true;
        this.allFilters = true;
        this.updateImages();
      }
    }
  }

  getTranslations() {
    let label = 'SEARCH_CRITERIA.HIDE_ALL';
    if (!this.allFilters) {
      label = 'SEARCH_CRITERIA.SHOW_ALL';
    }
    this.translate.get(label).subscribe( result => {
      this.tissueToggleLabel = result;
    });
  }

  updateImages() {
    this.images = [];
    this.allImages = [];
    this.tissues = new Set<ImageFilter>();
    const allTissues = new Set<string>();
    if (this.datasource) {
      for (let i = 0; i < this.datasource.length; i++) {
        const image = {
          src: this.imagePath +  this.datasource[i].filename,
          caption: this.datasource[i].description,
          thumb: this.thumbPath +  this.datasource[i].filename,
          tissues: this.datasource[i].tissues,
          visible: true
        };
        this.images.push(image);
        this.allImages.push(image);
        if (this.datasource[i].tissues) {
          for (const tissue of this.datasource[i].tissues) {
            allTissues.add(tissue)
          }
        }
      }
      if (allTissues.size > 0) {
        for (const t of allTissues) {
          if (t) {
            const filter = new ImageFilter();
            filter.active = true;
            filter.label = t;
            filter.value = t;
            // haetaan oikean kielinen arvo kudoksen nimelle.
            if (this.tissuesData) {
              const description = this.tissuesData.find( item => {
                if (item.code === t) {
                  return item;
                }
              });
              if (description) {
                filter.label = description.description;
              }
            }
            this.tissues.add(filter);
          }
        }
      }
    }
  }

  filterImagesByTissue(filter: ImageFilter) {
    // Jos kyseessä on ensimmäinen klikkaus filterihin, niin valitaan vain klikattu
    if (this.firstFiltering) {
      this.toggleAllTissueFilters();
      filter.active = true;
      this.firstFiltering = false;
    } else {
      filter.active = !filter.active;
    }

    this.images = this.allImages.map( item => {
      let visible = true;
      let active = false;
      if (item.tissues) {
        for (const tissue of item.tissues) {
          for (const tissueFilter of this.tissues) {
            if (tissueFilter.value === tissue) {
              active = tissueFilter.active;
              if (active) {
                break;
              }
            }
          }
          if (active) {
            break;
          }
        }
        visible = active;
      }
      item.visible = visible;
      return item;
    });
  }

  toggleAllTissueFilters() {
    this.allFilters = !this.allFilters;
    if (this.allFilters) {
      this.firstFiltering = true;
    }
    this.images = this.images.map(item => {
        item.visible = this.allFilters;
        return item;
    });
    for (const tissue of this.tissues) {
      tissue.active = this.allFilters;
    }
    this.getTranslations();
  }

  openImage(idx) {
    const imageList = this.images.filter( item => item.visible);
    this.lightbox.open(imageList, idx);
    /*
    this.selectedIndex = idx;
    this.selectedImage = this.datasource[idx];
    this.showDialog = true;
    this.getScreenSize();
    this.setButtons();
     */
  }

  // Listener for arrow left/rigth keys
  /*
  @HostListener('window:keyup', ['$event'])
  onDocumentKeyUp(event: KeyboardEvent) {
    event.preventDefault();
    if (this.showDialog) {
      if (event.keyCode === KEY_CODE.LEFT_ARROW) {
        this.value = false;
        this.navigate(this.value);
      }
      if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
        this.value = true;
        this.navigate(this.value);
      }
    }
  }
  */
  // Lister for window resizing.
  /*
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    let widthRatio = 1.0;
    let heightRatio = 1.0;
    if (window.innerWidth > 600) {
      widthRatio = 0.7;
    }
    if (window.innerHeight > 600) {
      heightRatio = 0.7;
    }

    this.dialogHeight = window.innerHeight * heightRatio;
    this.dialogWidth = window.innerWidth * widthRatio;
    this.contentHeight = window.innerHeight * heightRatio;
    this.contentWidth = this.dialogWidth; // window.innerWidth * widthRatio;

    this.contentHeight = window.innerHeight;
    this.contentWidth = window.innerWidth;

  }
  */
  // Align image to center
  /*
  alignToCenter() {
    const imageWidth = this.selectedImg.nativeElement.style.width.replace('px', '');
    let left = this.selectedImg.nativeElement.style.left = ((this.contentWidth / 2) -  (imageWidth / 2));
    if (left < 0) {
      left = 10;
    }
    this.selectedImg.nativeElement.style.left = left + 'px';

  }
  */
  // Load event. Checks when image has loaded and sets the current image settings
  /*
  initImage(event) {

    if ((event) && (event.target)) {
      this.loading = false;
      this.selectedImage.width = event.target.naturalWidth;
      this.selectedImage.height = event.target.naturalHeight;
      this.selectedImage.ratio = 1;
      if ((this.selectedImage.height > 0) && (this.selectedImage.width > 0)) {
        this.selectedImage.ratio =
          Math.min(this.selectedImage.width / this.selectedImage.height, this.selectedImage.height / this.selectedImage.width)
      }
      this.selectedImg.nativeElement.style.height = event.target.naturalHeight + 'px';
      this.selectedImg.nativeElement.style.width = event.target.naturalWidth + 'px';

      this.fitToDialog();
    }

  }
*/
  // Init image size to fit to screen
  /*
  fitToDialog() {
    if (this.selectedImg) {
      const imgWidth = this.selectedImage.width;
      const imgHeight = this.selectedImage.height;
      const ratio = this.selectedImage.ratio;
      if (imgHeight > this.contentHeight) {
        this.selectedImg.nativeElement.style.height = (this.contentHeight - 15) + 'px';
        this.selectedImg.nativeElement.style.width = (this.selectedImg.nativeElement.width * ratio)  + 'px';
      } else if (imgWidth > this.contentWidth) {
        this.selectedImg.nativeElement.style.width = (this.contentWidth - 15) + 'px';
        this.selectedImg.nativeElement.style.height = (this.selectedImg.nativeElement.height * ratio) + 'px';
      }
    }
    this.alignToCenter();
  }
  */
  // Init image size to orginal
  /*
  fullSize() {
    this.selectedImg.nativeElement.style.height = this.selectedImage.height + 'px';
    this.selectedImg.nativeElement.style.width = this.selectedImage.width + 'px';
    this.alignToCenter();
  }
  */
  /*
  setButtons() {
    this.showPrev = true;
    this.showNext = true;
    if (this.selectedIndex === 0) {
      this.showPrev = false;
    }
    if (this.selectedIndex === this.datasource.length - 1) {
      this.showNext = false;
    }
  }
   */
  // Navigate to next or previous image
  /*
  navigate(forward) {
    this.selectedImage = null;
    this.loading = true;
    let index = this.selectedIndex + (forward ? 1 : -1);
    if (index < 0) {
      index = 0;
    } else if (index >= this.datasource.length ) {
      index = this.datasource.length - 1;
    }
    this.selectedImage = this.datasource[index];
    this.selectedIndex = index;
    this.setButtons();
  }
  */

  // Zoom in or out the current image
  /*
  zoom(out: boolean) {
    const zoomLevel = 35;
    let ratio = 1;
    if (this.selectedImage.height > 0) {
      ratio = this.selectedImage.width / this.selectedImage.height;
    }

    if (out) {
      this.selectedImg.nativeElement.style.height = (this.selectedImg.nativeElement.height - zoomLevel) + 'px';
      this.selectedImg.nativeElement.style.width = (this.selectedImg.nativeElement.width - (zoomLevel * ratio)) + 'px';
    } else {
      this.selectedImg.nativeElement.style.height = (this.selectedImg.nativeElement.height + zoomLevel)  + 'px';
      this.selectedImg.nativeElement.style.width = (this.selectedImg.nativeElement.width + (zoomLevel * ratio)) + 'px';
    }
    this.alignToCenter();
  }
  */
}
