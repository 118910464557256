import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {SearchCriteria} from '../../models/SearchCriteria';
import {ImageSearchResult} from '../../models/ImageSearchResult';
import {Gallery} from '../../models/Gallery';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ImageService} from '../../services/image-service';
import {Router} from '@angular/router';

import * as locations from '../../../assets/data/anatomy/locations.json';
@Component({
  selector: 'app-fracture-gallery',
  templateUrl: './fracture-gallery.component.html',
  styleUrls: ['./fracture-gallery.component.css']
})
export class FractureGalleryComponent implements OnInit, OnDestroy {

  public locationsData: any = (locations as any).default;

  public galleryId: number;
  private searchCriteria$: Subscription;
  private searchResult$: Subscription;

  public searchCriteria: SearchCriteria;
  public searchResult: ImageSearchResult = null;
  public gallery: Gallery;
  public showSearchResult = false;
  public lang: string;

  constructor(
    private translate: TranslateService,
    private imageService: ImageService,
    private router: Router) {

    this.galleryId = Number(this.router.url.substr(1));

  }

  ngOnInit() {

    this.lang = this.translate.currentLang;
    this.getGallery();

    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.lang = this.translate.currentLang;
        this.getGallery();
      });

    // Clear former search result and criteria
    this.imageService.setImageSearchResult(null);
    this.imageService.setSearchCriteria(null);

    // Subscribe search criterias and search result from imageService
    this.searchCriteria$ = this.imageService.searchCriteriaObservable$
      .subscribe(
        result => {
          this.searchCriteria = result;
        }
      );

    this.searchResult$ = this.imageService.imageSearchResultObservable$
      .subscribe(
        result => {
          if (result) {
            this.showSearchResult = true;
          }

          this.searchResult = result;
        }
      );
  }

  ngOnDestroy() {
    if (this.searchCriteria$) {
      this.searchCriteria$.unsubscribe();
    }
    if (this.searchResult$) {
      this.searchResult$.unsubscribe();
    }
  }

  async getGallery() {
    // Fetch gallery inf
    this.gallery = await this.imageService.getGallery(this.galleryId, this.translate.currentLang);

  }
}
