import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public error: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  async ngOnInit() {

    this.activatedRoute.queryParams.subscribe(async params => {
      if (params.code) {
        try {
          this.authService.login(params.code).subscribe( async result => {
            await this.authService.userInfo();
            await this.router.navigate(['/']);
          })
        } catch (err) {
          this.error = err;
        }
      }
    });
  }

}
