<h4>{{title}}</h4>
<div *ngFor="let item of items" class="flat-list">
  <p-checkbox
    [name]="name"
    [value]="item.code"
    [(ngModel)]="selectedItems"
    label="{{item.description}}"
  >
  </p-checkbox>
</div>

