<section class="gallery-content">
  <section *ngIf="tissues && showFilters">
    <div class="p-mb-1">{{ 'SEARCH_CRITERIA.FILTER_BY_TISSUES' | translate }} </div>
    <span class="p-tag p-color-dark filter-item" (click)="toggleAllTissueFilters()">{{tissueToggleLabel}}</span>
    <span *ngFor="let tissue of tissues" class="p-tag p-color-dark filter-item" [class.p-tag-disabed]="!tissue.active" (click)="filterImagesByTissue(tissue)">{{tissue.label}}</span>
  </section>

  <div *ngIf="images">
    <ul class="images">
      <li *ngFor="let image of images; let idx = index" [class.hide]="!image.visible">
        <a class = "thumb" (click)="openImage(idx)">
          <img src="{{image.thumb }}">
        </a>
      </li>
    </ul>
  </div>
</section>
<!--
<section *ngIf="selectedImage">
  <p-dialog [showHeader]="true"
            [(visible)]="showDialog"
            [draggable]="false"
            [resizable]="false"
            [dismissableMask] = "true"
            [maximizable]="true"
            [modal]= "true"
            [blockScroll]="true"
            [style]="{width: '75vw'}"
            #dia
  >
    <div>
      <img *ngIf="selectedImage" src="{{imagePath + selectedImage.filename}}" #selectedImg (load)="initImage($event)" id="selectedImg">
      <div *ngIf="loading">Loading...</div>
    </div>
    <p-footer *ngIf="selectedImage" >
      <p class="image-description">{{selectedImage.description}}</p>
      <p-button label="{{'IMAGE_DIALOG.BTN_ZOOM_OUT' | translate}}" icon="pi pi-search-minus" iconPos="left" (onClick)="zoom(true)"></p-button>
      <p-button label="{{'IMAGE_DIALOG.BTN_ZOOM_IN' | translate}}" icon="pi pi-search-plus" iconPos="left" (onClick)="zoom(false)"></p-button>
      <p-button label="{{'IMAGE_DIALOG.BTN_FIT' | translate}}" icon="pi pi-window-minimize" iconPos="left" (onClick)="fitToDialog()"></p-button>
      <p-button label="{{'IMAGE_DIALOG.BTN_FULL' | translate}}" icon="pi pi-window-maximize" iconPos="left" (onClick)="fullSize()"></p-button>
      <p-button *ngIf="showPrev" label="{{'IMAGE_DIALOG.BTN_PREVIOUS' | translate}}"  icon="pi pi-angle-double-left" iconPos="left" (onClick)="navigate(false)"></p-button>
      <p-button *ngIf="showNext" label="{{'IMAGE_DIALOG.BTN_NEXT' | translate}}" icon="pi pi-angle-double-right" iconPos="right" (onClick)="navigate(true)"></p-button>
    </p-footer>
  </p-dialog>
</section>
-->







