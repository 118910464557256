<section class="p-grid">
  <section class="p-col-12 p-sm-4 left-panel">
    <app-search-panel
      [galleryId]="galleryId"
      [showKeyword]="true"
      [locationsData]="locationsData[lang]"
      [typesData]="typeData[lang]"
      [autoCompleteResultSize]="10"
      [searchType]="'DIAGNOSE'"
      [routelink]="'skindisease'"
      [graphicalSearch]="false"
      searchTip="{{'SIDE_NAV.SEARCH_SKINDISEASE' | translate}}"
    ></app-search-panel>
  </section>

  <section class="p-col-12 p-sm-8" >
    <section *ngIf="gallery">
      <h2>{{gallery.name}}</h2>
      <!--<p>{{gallery.description}}</p>-->
      <hr>
    </section>
      <router-outlet></router-outlet>
  </section>
</section>
