<div *ngIf="showSearchResult">
  <app-search-criteria-panel
    [searchCriteria]="searchCriteria"
  ></app-search-criteria-panel>

  <section *ngIf="searchResult">
    <div *ngFor="let key of searchResultKeysList">
      <app-diagnose-list [galleryId]="galleryId"
                         [title]="key.description"
                         [datasource]="searchResult.resultGroups.get(key.key)"
                         [routeLink]="'./diagnose'"
      >
      </app-diagnose-list>
    </div>
  </section>

</div>
