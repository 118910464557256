<section class="p-grid">
  <section class="p-col-1 p-sm-3 left-panel">
    <app-search-panel
      [galleryId]="galleryId"
      [locationsData]="locationsData[lang]"
      [autoCompleteResultSize]="20"
      [showAge]="true"
      [showSex]="true"
    ></app-search-panel>
  </section>

  <!-- Content panel class="content-container"-->
  <section class="p-col-11 p-sm-9" >
    <section *ngIf="gallery">
      <h2>{{gallery.name}}</h2>
      <hr>
      <p *ngIf="!showSearchResult">{{gallery.description}}</p>
    </section>

    <div *ngIf="showSearchResult">
      <app-search-criteria-panel
        [searchCriteria]="searchCriteria"
      ></app-search-criteria-panel>

      <hr>

      <!-- Images -->
      <app-gallery
        [galleryId]="galleryId"
        [datasource]="searchResult.result"
      ></app-gallery>
    </div>

  </section>
</section>
