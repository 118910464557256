import {Component, Input, OnInit} from '@angular/core';
import {SearchCriteria} from '../../../models/SearchCriteria';

@Component({
  selector: 'app-search-criteria-panel',
  templateUrl: './search-criteria-panel.component.html',
  styleUrls: ['./search-criteria-panel.component.scss']
})
export class SearchCriteriaPanelComponent implements OnInit {

  @Input() searchCriteria: SearchCriteria;
  @Input() searchResultCount: number;
  constructor() { }

  ngOnInit() {
    if (this.searchCriteria.location) {
      const locations = this.searchCriteria.location.split(',');

    }
  }

}
