import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NoopAnimationsModule} from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { RouterModule} from '@angular/router';
import {  StorageServiceModule } from 'ngx-webstorage-service';

import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {CardModule} from 'primeng/card';
import {DialogModule} from 'primeng/dialog';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {PanelModule} from 'primeng/panel';
import { AppComponent } from './app.component';

import { AnatomyGalleryComponent } from './components/anatomyGallery/anatomyGallery.component';
import { LasteRTGKuvastoComponent } from './components/laste-rtg-kuvasto/laste-rtg-kuvasto.component';
import { RontgenAnatomiakuvastoComponent } from './components/rontgen-anatomiakuvasto/rontgen-anatomiakuvasto.component';
import { MurtumatRTGKuvastoComponent } from './components/murtumat-rtg-kuvasto/murtumat-rtg-kuvasto.component';
import { IhotautikuvastoService } from './services/ihotautikuvasto.service';

import { NavbarComponent } from './navbar/navbar.component';
import {SidebarModule} from 'primeng/sidebar';
import {ButtonModule} from 'primeng/button';

import { GalleryCommonModule} from './modules/galleryCommon/galleryCommon.module';
import {appRoutes} from '../routes';
import { ImageService} from './services/image-service';
import { ImageImageService } from './services/image-image-service';


import { RontgenGalleryComponent } from './components/rontgen-gallery/rontgen-gallery.component';
import { ImgPreviewComponent } from './components/img-preview/img-preview.component';
import { GalleryHomeComponent } from './components/galleryHome/galleryHome.component';
import { ChildRtgGalleryComponent } from './components/child-rtg-gallery/child-rtg-gallery.component';
import { FractureGalleryComponent } from './components/fracture-gallery/fracture-gallery.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import {UnathorizedInterceptor} from './interceptors/UnathorizedInterceptor';
import { TokenInterceptor } from './interceptors/TokenInterceptor';
import { SkinDiseaseGalleryComponent } from './components/skin-disease-gallery/skin-disease-gallery.component';
import { DiagnoseComponent } from './components/skin-disease-gallery/diagnose/diagnose.component';
import { SearchComponent } from './components/skin-disease-gallery/search/search.component';
import { AuthComponent } from './components/auth/auth.component';


export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
  declarations: [
    AppComponent,
    GalleryHomeComponent,
    AnatomyGalleryComponent,
    LasteRTGKuvastoComponent,
    MurtumatRTGKuvastoComponent,
    RontgenAnatomiakuvastoComponent,
    NavbarComponent,
    RontgenGalleryComponent,
    ImgPreviewComponent,
    ChildRtgGalleryComponent,
    FractureGalleryComponent,
    LoginComponent,
    LogoutComponent,
    SkinDiseaseGalleryComponent,
    DiagnoseComponent,
    SearchComponent,
    AuthComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    StorageServiceModule,
    MenuModule,
    MenubarModule,
    SidebarModule,
    ButtonModule,
    CardModule,
    DialogModule,
    GalleryCommonModule,
    MessagesModule,
    MessageModule,
    PanelModule
  ],
  exports: [
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnathorizedInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ImageService,
    ImageImageService,
    IhotautikuvastoService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
