import { Injectable } from '@angular/core';
import {  HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {SearchCriteria} from '../models/SearchCriteria';
import {environment} from '../../environments/environment';
import {Diagnose} from '../models/Diagnose';
import {DiagnoseSearchResult} from '../models/DiagnoseSearchResult';

@Injectable({
  providedIn: 'root'
})
export class DiagnoseService {

  private apiBaseUrl = environment.apiBaseUrl;
  private diagnoseSource = new BehaviorSubject<Diagnose>(null);
  private diagnoseSearchResultSource = new BehaviorSubject<DiagnoseSearchResult>(null);
  private searchCriteriaSource = new BehaviorSubject<SearchCriteria>(null);

  public diagnoseObservable$ = this.diagnoseSource.asObservable();
  public diagnoseSearchResultObservable$ = this.diagnoseSearchResultSource.asObservable();
  public searchCriteriaObservable$ = this.searchCriteriaSource.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  async getDiagnose(id, language, galleryId): Promise<Diagnose> {
    const diagnose = await this.http.get<Diagnose>(`${this.apiBaseUrl}/diagnose/${language}/${id}/${galleryId}`).toPromise();
    this.setDiagnose(diagnose);
    return diagnose;
  }

  async searchDiagnoses(searchCriteria: SearchCriteria): Promise<DiagnoseSearchResult> {
    const _searchResult =  await this.http.post<DiagnoseSearchResult>(`${this.apiBaseUrl}diagnose/search/`, searchCriteria).toPromise();
    if (_searchResult.info) {
      searchCriteria.resultCount = _searchResult.info.count;
    }
    this.setSearchCriteria(searchCriteria);
    this.setDiagnoseSearchResult(_searchResult);
    return _searchResult;
  }

  setDiagnose(d: Diagnose) {
    this.diagnoseSource.next(d);
  }

  // Observebles to share the given search result
  setDiagnoseSearchResult(sr: DiagnoseSearchResult) {
    if (sr) {
      sr.resultGroups = new Map<string, Array<Diagnose>>();
      for (let i = 0; i < sr.results.length; i++) {
        const diagnose = sr.results[i];
        if (sr.resultGroups.has(diagnose.keywords)) {
          sr.resultGroups.get(diagnose.keywords).push(diagnose);
        } else {
          sr.resultGroups.set(diagnose.keywords, new Array<Diagnose>(diagnose));
        }
      }
    }
    this.diagnoseSearchResultSource.next(sr);
  }

  // Observebles to share the given search criteria
  setSearchCriteria(sc: SearchCriteria) {
    this.searchCriteriaSource.next(sc);
  }

}
